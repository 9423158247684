import React, { useState, useMemo } from 'react'
import StudentGradeDetails from './StudentGradeDetails'
import { useStudentGradeContext } from '../../contexts/StudentGradeContext'
import { isStudentProgressFlag, getCourseIds } from '../../config'
import './StudentGradeSection.css'
import { changeParticipationPercentToGrade } from '../../utilities/gradeUtils'

function StudentGradeSection ({
  cohortId,
  section,
  courseData,
  chapterNumber,
  sectionNumber,
  cohort,
  courseId
}) {
  let chapterSectionNumber = ''
  if (chapterNumber && sectionNumber) {
    const { course_uuid: courseUUID } = courseData
    const courseIds = getCourseIds()

    const isComputerScience = [
      courseIds['computerscience-i.plus'],
      courseIds['computerscience-i']
    ].includes(courseUUID)
    let chapterIndex = chapterNumber
    let sectionIndex = sectionNumber
    if (isComputerScience) {
      chapterIndex--
      sectionIndex--
    }
    chapterSectionNumber = `${chapterIndex}.${sectionIndex} `
  }
  const sectionName = chapterSectionNumber + section.title
  const isParticipation = section.type === 'participation'
  const [expanded, setExpanded] = useState(false)
  const { studentProgress } = useStudentGradeContext()
  const isExamOrAssignment = section.type === 'exam' ||
    section.type === 'WritingAssignmentChapterRecord' ||
    section.type === 'CodingAssignmentChapterRecord'

  const { sectionOverviewData, gradeData, maxParticipationGrade } =
    useStudentGradeContext()

  const getSectionScore = () => {
    if (isParticipation) {
      return gradeData
        ? changeParticipationPercentToGrade({
          percent: gradeData.grade,
          maxParticipationGrade
        })
        : null
    }
    if (!studentProgress) return
    if (
      [
        'CodingAssignmentChapterRecord',
        'WritingAssignmentChapterRecord'
      ].includes(section.type)) {
      const assignmentProgress = studentProgress['assignment-progress'][section.chapter_uuid]
      return assignmentProgress &&
        assignmentProgress.grade
    }
    if (section.type === 'exam') return studentProgress['exam-percentage'][section.chapter_uuid]
    return studentProgress && studentProgress['quiz-percentage'][section.section_uuid]
  }

  const getSectionProgress = () => {
    if (isParticipation) return null
    if (section.type !== 'exam' &&
      section.type !== 'CodingAssignmentChapterRecord' &&
      section.type !== 'WritingAssignmentChapterRecord') {
      return studentProgress && studentProgress.sectionProgress[section.section_uuid]
    }
  }

  const getProgressText = (minimumSectionProgress, sectionProgress) => {
    if (!minimumSectionProgress && !sectionProgress) return '--'
    const minimumProgress = minimumSectionProgress
      ? `${minimumSectionProgress}% Min` : '--'
    const totalProgress = sectionProgress
      ? `${sectionProgress}% Total` : '--'
    return minimumProgress + ' / ' + totalProgress
  }

  const getMinimumSectionProgress = () => {
    if (isParticipation) return null
    if (section.type !== 'exam' &&
    section.type !== 'CodingAssignmentChapterRecord' &&
    section.type !== 'WritingAssignmentChapterRecord') {
      return studentProgress &&
      studentProgress.minimumSectionProgress[section.section_uuid]
    }
  }

  const getAttemptData = studentSectionData => {
    const sectionKey = isExamOrAssignment ? section.chapter_uuid : section.section_uuid
    const keys = Object.keys(studentSectionData)

    for (let i = 0; i < keys.length; i++) {
      const currentSectionByTitle = studentSectionData[keys[i]]
      if (!currentSectionByTitle) continue

      const currentSection = currentSectionByTitle[sectionKey]
      if (currentSection === undefined || currentSection === null) continue

      return currentSection
    }
  }

  const minSectionProgress = useMemo(() => getMinimumSectionProgress()
  // eslint-disable-next-line
  ,[section])
  const sectionProgress = useMemo(() => getSectionProgress()
  // eslint-disable-next-line
  ,[section])

  const sectionScore = useMemo(() => getSectionScore()
    // eslint-disable-next-line
      ,[section])
  const selectedStudent = (sectionOverviewData || []).find(s => s.id === gradeData.id || s.email === gradeData.email)
  let attempts
  if (selectedStudent && !isParticipation) {
    const selectedSection = getAttemptData(selectedStudent) || {}

    if (isExamOrAssignment) {
      attempts = selectedSection > 0 ? 1 : 0
    } else {
      if (Object.keys(selectedSection).length) {
        attempts = Object.values(selectedSection).filter(s => s !== '').length
      } else {
        attempts = typeof sectionProgress === 'number' ? 1 : 0
      }
    }
  } else {
    attempts = isParticipation ? '--' : 0
  }
  return (
    <>
      <div className={expanded ? 'table-grid-body expanded' : 'table-grid-body'} onClick={() => setExpanded(!expanded)}>
        <span className={'button-container'}>
          <div className={expanded ? 'expand-btn-expanded' : 'expand-btn'} />
        </span>
        <span className={'section-container'} data-testid='sectionName'>{sectionName}</span>
        <span className={'header'}>Final Score</span>
        <span>{
          typeof sectionScore === 'number' ? `${sectionScore}${isParticipation ? '' : '%'}` : '--'
        }</span>
        <span className={'header'}>Attempts</span>
        <span id={'attempts'}>{attempts}</span>
        <span className={'header'}>Progress</span>
        {isStudentProgressFlag ? <span data-testid='sectionProgress'>
          {getProgressText(minSectionProgress, sectionProgress)}
        </span>
          : <span>{typeof sectionProgress === 'number'
            ? `${sectionProgress}%` : '--'}</span>}
      </div>
      {expanded && <div className={'details-tr-expanded'}>
        <span />
        <span className={'details-td'}>
          <StudentGradeDetails
            cohortId={cohortId}
            section={section}
            studentProgress={studentProgress}
            courseData={courseData}
            cohort={cohort}
          />
        </span>
      </div>}
    </>
  )
}

export default StudentGradeSection
