import React, { useEffect, useLayoutEffect, useState } from 'react'
import {
  BodyContainer,
  CloseButton,
  ColumnTitle,
  ExpandedRecordsModal,
  HeaderContainer,
  LinkedItem,
  LinkedItems, LinkNewRecord
} from './styled'
import { ReactComponent as CloseIcon } from '../../assets/icons/modal-close.svg'
import { ReactComponent as DashCircle } from '../../assets/icons/dash-circle.svg'
import { ReactComponent as LinkNewRecordIcon } from '../../assets/icons/plus-sign.svg'
import useClickAway from '../../Hooks/useClickAway'
import UnlinkRecordConfirmation from './UnlinkRecordConfirmation'
import {
  CloseIconWrapper, ModalBody,
  ModalHeaderContainer, ModalItem, ModalTitleContainer,
  ModalWrapper
} from '../../views/GGURegistration/style/ProspectPage'
import { Description } from '../../styles/text'
import {
  ClearIcon,
  SearchField, SearchIcon,
  SearchTextField
} from '../../views/GGURegistration/style'
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'
import api from '../../api'

function ExpandedRecords ({
  setExpandLinkedRecords,
  handleClick,
  metaData,
  cell,
  cellRef,
  tableRef
}) {
  const [modalPosition, setModalPosition] = useState({ left: 0, top: 0 })
  const [unlinkRecordConfirmation, setUnlinkRecordConfirmation] = useState(null)
  const [showAddNewRecordModal, setShowAddNewRecordModal] = useState(false)
  const [availableRecords, setAvailableRecords] = useState([])
  const [filterRecords, setFilterRecords] = useState([])
  const [isLoadingRecords, setIsLoadingRecords] = useState(false)
  const [searchText, setSearchText] = useState('')

  const modalRef = useClickAway(() => setExpandLinkedRecords(false), {
    onlyIn: tableRef
  })

  const { value: linkedRecords } = cell

  useEffect(() => {
    if (!showAddNewRecordModal) return
    const getAvailableRecords = async () => {
      setIsLoadingRecords(true)
      const { joinedTo: tableName } = metaData
      const result = await api.getTableIdValues({ tableName })
      setIsLoadingRecords(false)
      const filteredResult = result?.filter(({ id }) => {
        return !linkedRecords.some((record) => record.id === id)
      })
      setAvailableRecords(filteredResult)
      setFilterRecords(filteredResult)
    }
    getAvailableRecords()
    // eslint-disable-next-line
  }, [showAddNewRecordModal])

  useEffect(() => {
    if (!searchText) {
      availableRecords?.length && setFilterRecords(availableRecords)
      return
    }
    const filterRecords = availableRecords.filter(({ value }) => {
      return value?.toString().toLowerCase().includes(searchText.toLowerCase())
    })
    setFilterRecords(filterRecords)
    // eslint-disable-next-line
  }, [searchText])

  useLayoutEffect(() => {
    const cellRect = cellRef.current?.getBoundingClientRect()
    const { top, left, height } = cellRect || {}
    const modalRect = modalRef.current?.getBoundingClientRect()
    const modalHeight = modalRect.height

    if (top + height + modalHeight > window.innerHeight) {
      setModalPosition({ left: left - 12, top: top - modalHeight - 7 })
      return
    }
    setModalPosition({ left: left - 12, top: top + height + 7 })
  }, [cellRef, modalRef])

  const closeUnlinkRecordModal = () => {
    setUnlinkRecordConfirmation(false)
  }

  const handleModalClose = () => {
    setShowAddNewRecordModal(false)
  }

  const handleTextChange = (text) => {
    setSearchText(text)
  }

  const handleAddRecord = async (record) => {
    setShowAddNewRecordModal(false)
    const newRecord = {
      [cell.column?.id]: [...cell.value, record]
    }
    console.log('cell', cell)
    cell.column.updateTable(cell.row.index, cell.column.id, [...cell.value, record])
    await api.updateRecord(
      newRecord,
      cell.column.metaData.tableName,
      cell.row.original.id
    )
  }

  return (
    <>
      {unlinkRecordConfirmation && (
        <UnlinkRecordConfirmation
          closeUnlinkRecordModal={closeUnlinkRecordModal}
          unlinkRecordConfirmation={unlinkRecordConfirmation}
          cell={cell}
        />
      )}
      <ExpandedRecordsModal
        ref={modalRef}
        style={{ top: modalPosition.top, left: modalPosition.left }}
        onContextMenu={(event) => event.stopPropagation()}
      >
        <HeaderContainer>
          <ColumnTitle>{metaData.camelCase}</ColumnTitle>
          <LinkNewRecord onClick={() => setShowAddNewRecordModal(true)}>
            <LinkNewRecordIcon />
              LINK A RECORD
          </LinkNewRecord>
          <CloseButton onClick={() => setExpandLinkedRecords(null)}>
            <CloseIcon />
          </CloseButton>
        </HeaderContainer>
        <BodyContainer>
          <LinkedItems>
            {linkedRecords.map((record) => {
              if (!record) return null
              return (
                <LinkedItem
                  key={record.id}
                  onClick={() => handleClick(record, metaData?.joinedTo)}
                >
                  {record.value}
                  <DashCircle
                    data-testid='unlink-record'
                    onClick={() => setUnlinkRecordConfirmation({ record })}
                  />
                </LinkedItem>
              )
            })}
          </LinkedItems>
        </BodyContainer>
      </ExpandedRecordsModal>
      {showAddNewRecordModal && (
        <ModalWrapper data-testid='add-new-record-modal' width='400px'
          top='40px' center ref={modalRef}>
          <ModalHeaderContainer>
            <ModalTitleContainer>
              <Description>Link record from {metaData.joinedTo}</Description>
              <CloseIconWrapper onClick={handleModalClose}>
                <CloseIcon />
              </CloseIconWrapper>
            </ModalTitleContainer>

            <SearchField>
              <SearchTextField
                placeholder='Search records'
                value={searchText}
                onChange={(e) => handleTextChange(e.target.value)}
              />
              {searchText ? (
                <ClearIcon onClick={() => handleTextChange('')} />
              ) : (
                <SearchIcon />
              )}
            </SearchField>
          </ModalHeaderContainer>

          {isLoadingRecords && (
            <ModalBody style={{ height: '100px' }}>
              <LoadingSpinner />
            </ModalBody>
          )}

          <ModalBody>
            {filterRecords?.slice(0, 100)?.map((record, index) => (
              <ModalItem
                data-testid='new-record-item'
                key={index}
                onClick={() => handleAddRecord(record)}
              >
                {record?.value}
              </ModalItem>
            ))}
          </ModalBody>
        </ModalWrapper>
      )}
    </>
  )
}

ExpandedRecords.displayName = 'ExpandedRecords'
export default ExpandedRecords
