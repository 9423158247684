import React, { useEffect, useState } from 'react'
import {
  HeaderCell,
  Row,
  StudentCell,
  StudentRecordRowWrapper,
  StudentRow,
  TableHeaderWrapper
} from './style'
import frontArrow from '../../assets/icons/front-arrow-caret.svg'
import api from '../../api'
import { useDebouncedEffect } from '../../Hooks/useDebounce'
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'
import PaginationBar from './PaginationBar'
import StudentRecordNotFound from './StudentRecordNotFound'

const StudentsTable = ({ filterText }) => {
  const [studentsList, setStudentsList] = useState([])
  const [isStudentsListLoading, setIsStudentsListLoading] = useState(true)
  const [pagesCount, setPagesCount] = useState(0)
  const [recordsCount, setRecordsCount] = useState(0)
  const [currentPageRows, setCurrentPageRows] = useState([])

  useEffect(() => {
    setIsStudentsListLoading(true)
  }, [filterText])

  useDebouncedEffect(() => {
    fetchStudents()
  }, [filterText], 1000)

  const fetchStudents = async () => {
    const trimmedText = filterText.trimStart().trimEnd()
    const firstText = trimmedText?.split(' ')[0]
    const secondText = trimmedText?.split(' ')[1]
    const filters = {
      firstname: {
        ilike: firstText
      },
      email: {
        eq: firstText
      },
      id_: {
        eq: firstText
      },
      lastName: { ilike: secondText || firstText },
      rules: [
        {
          field: 'firstName',
          operator: 'ilike',
          value: firstText
        },
        {
          field: 'email',
          operator: 'eq',
          value: firstText
        },
        {
          field: 'id_',
          operator: 'eq',
          value: firstText
        },
        {
          field: 'lastName',
          operator: 'ilike',
          value: secondText || firstText
        }
      ]
    }
    const data = await api.getTableFilter({
      tableName: 'students',
      filters
    })
    const rawStudentsList = data?.rows || []
    const studentsList = rawStudentsList.map((student) => {
      const { id: studentId, email, preferredName, firstName, lastName } =
        student?.fields
      return {
        studentId,
        email,
        preferredName,
        fullName: `${firstName || ''} ${lastName || ''}`
      }
    })
    setStudentsList(studentsList)
    setPagesCount(Math.ceil(studentsList.length / 10))
    setRecordsCount(studentsList.length)
    setCurrentPageRows([])
    setIsStudentsListLoading(false)
  }
  const pageChangeHandler = (selectedPage) => {
    const offset = 10 * (selectedPage - 1)
    const currentPageRows = studentsList.slice(offset, offset + 10)
    setCurrentPageRows(currentPageRows)
  }

  if (isStudentsListLoading) return (<LoadingSpinner id='loading-spinner' />)

  if (studentsList.length < 1) {
    const trimmedFilterText = filterText.trimStart().trimEnd()
    return (<StudentRecordNotFound filterText={trimmedFilterText} />)
  }

  return (
    <>
      <TableHeaderWrapper>
        <Row>
          <HeaderCell style={{ width: 200 }}>full name</HeaderCell>
          <HeaderCell style={{ width: 180 }}>Preferred name</HeaderCell>
          <HeaderCell style={{ width: 280 }}>email</HeaderCell>
          <HeaderCell style={{ width: 286 }}>student id</HeaderCell>
          <HeaderCell style={{ width: 12 }} />
        </Row>
      </TableHeaderWrapper>
      {currentPageRows.map((student, index) => {
        const { studentId, email, preferredName, fullName } = student
        return (
          <StudentRecordRowWrapper key={index}>
            <StudentRow
              style={{ cursor: 'pointer' }}
              onClick={() => {
                window.location.href = `#/student-record-details/${studentId}/${email}`
              }}
            >
              <StudentCell style={{ width: 200 }}>{fullName}</StudentCell>
              <StudentCell style={{ width: 180 }}>{preferredName}</StudentCell>
              <StudentCell style={{ width: 280 }}>{email}</StudentCell>
              <StudentCell style={{ width: 286 }}>{studentId}</StudentCell>
              <StudentCell style={{ width: 12, justifyContent: 'end' }}>
                <img src={frontArrow} alt='rectangle-plus' />
              </StudentCell>
            </StudentRow>
          </StudentRecordRowWrapper>
        )
      }
      )}
      <PaginationBar
        numberOfPages={pagesCount}
        recordsCount={recordsCount}
        pageChangeHandler={pageChangeHandler}
      />
    </>
  )
}
StudentsTable.displayName = 'StudentsTable'
export default StudentsTable
