import sortBy from 'lodash/sortBy'
import { EXAM } from '../../../Constants/chapter'
import api from '../../../api'
import {
  getDateAfterDays,
  getDateAfterWeeks,
  addMonthsToDate,
  dateToSecondsSinceEpoch,
  getDateString,
  secondsSinceEpoch
} from '../../../utilities/dateTimeUtil'
import orderBy from 'lodash/orderBy'
import { range } from 'lodash'
import moment from 'moment'
import { isCourseWithoutFinalExam } from '../../../utilities/courseUtils'

export const getSortedCourses = (courses) => {
  if (!courses || courses?.length < 1) return []
  return sortBy(
    courses.map(course => ({
      ...course,
      value: course.id,
      label: course.name
    })),
    'name'
  )
}

export const getActiveCourses = (courses) => {
  if (!courses || !courses?.length) return []
  const now = new Date()
  return courses.filter((course) => {
    const {
      cohort: { cohortEndTime }
    } = course
    const endDatePlusOneWeek = new Date(getDateAfterWeeks(cohortEndTime, 1))
    const endDateInSeconds = dateToSecondsSinceEpoch(endDatePlusOneWeek)
    return endDateInSeconds > secondsSinceEpoch(now)
  })
}

export const getSortedAssessments = (assessments) => {
  if (!assessments || assessments?.length < 1) return []
  assessments = assessments.map(assessment => ({
    ...assessment,
    value: assessment.examId,
    label: assessment.examTitle
  }))
  const futureAssessments = assessments
    .filter((assessment) => {
      const { unlockTime } = assessment
      const unlockTimeInSeconds = dateToSecondsSinceEpoch(new Date(unlockTime))
      return unlockTimeInSeconds > secondsSinceEpoch()
    })
    .sort((assessmentA, assessmentB) => {
      const { unlockTime: unlockTimeA } = assessmentA
      const { unlockTime: unlockTimeB } = assessmentB
      const unlockTimeAInSeconds = dateToSecondsSinceEpoch(new Date(unlockTimeA))
      const unlockTimeBInSeconds = dateToSecondsSinceEpoch(new Date(unlockTimeB))
      return unlockTimeAInSeconds - unlockTimeBInSeconds
    })
  const pastAssessments = assessments
    .filter((assessment) => {
      const { unlockTime } = assessment
      const unlockTimeInSeconds = dateToSecondsSinceEpoch(new Date(unlockTime))
      return unlockTimeInSeconds < secondsSinceEpoch()
    })
    .sort((assessmentA, assessmentB) => {
      const { unlockTime: unlockTimeA } = assessmentA
      const { unlockTime: unlockTimeB } = assessmentB
      const unlockTimeAInSeconds = dateToSecondsSinceEpoch(new Date(unlockTimeA))
      const unlockTimeBInSeconds = dateToSecondsSinceEpoch(new Date(unlockTimeB))
      return unlockTimeBInSeconds - unlockTimeAInSeconds
    })
  const sortedAssessments = futureAssessments.concat(pastAssessments)

  return orderBy(sortedAssessments, 'isOpen', 'desc')
}

export const getUnsubmittedAssessments = async (course, studentProgress) => {
  if (!course || !studentProgress) return []
  const courseData = await api.getCourseData(course.id)
  const { cohort, statusData } = course
  const milestones = statusData.find(cohorts => cohorts.id === cohort.id)?.milestones ?? []
  const writingAssignments = milestones.map(assignment => {
    const displayName = assignment.assignmentList[0].name.split('|')[1].trim()
    return {
      ...assignment,
      displayName: displayName
    }
  })
  const exams = courseData.chapters.filter(chapter => chapter.type === EXAM)
  const {
    'exam-complete': submittedExams,
    'assignment-progress': submittedAssignments
  } = studentProgress

  const unSubmittedExams = getUnsubmittedExams(
    exams, submittedExams, { ...cohort, courseId: course.id }
  )
  const unSubmittedAssignments = getUnsubmittedAssignments(
    writingAssignments,
    submittedAssignments
  )

  return unSubmittedExams.concat(unSubmittedAssignments)
}

export const getUnsubmittedExams = (exams, submittedExams, cohort) => {
  const submittedExamsIds = submittedExams
    ? Object.keys(submittedExams)
    : []
  const filteredExams = exams.filter(exam => !submittedExamsIds.includes(exam.chapter_uuid))
  const examsDates = getExamsDates(cohort, exams)
  const unSubmittedExams = filteredExams.length > 0
    ? filteredExams.map(exam => {
      const { unlockTime, lockTime, examNumber } = getSingleExamDates(examsDates, exam.chapter_uuid)
      const isOpen = isExamOpen(unlockTime, lockTime)
      return {
        examId: exam.chapter_uuid,
        examNumber,
        examTitle: exam.title,
        lockTime: lockTime,
        unlockTime: unlockTime,
        isOpen: isOpen
      }
    })
    : []
  return unSubmittedExams
}

export const getUnsubmittedAssignments = (writingAssignments, submittedAssignments) => {
  const submittedAssignmentsIds = submittedAssignments
    ? Object.keys(submittedAssignments)
    : []
  const filteredAssignments = writingAssignments.filter(
    (assignment) => !submittedAssignmentsIds.includes(assignment.datoAssignmentUUID)
  )
  const unSubmittedAssignments = filteredAssignments.length > 0
    ? filteredAssignments.map(assignment => {
      const displayName = assignment.assignmentList[0].name.split('|')[1].trim()
      const name = assignment.name
      const unlockTime = assignment.unlockTime
      const lockTime = assignment.lockTime
      const currentTime = secondsSinceEpoch()
      const isOpen = (!unlockTime || currentTime > unlockTime) &&
        currentTime < lockTime
      return {
        examId: assignment.id,
        examTitle: displayName,
        lockTime: assignment.lockTime,
        unlockTime: unlockTime,
        isOpen: isOpen,
        assessmentName: name
      }
    })
    : []

  return unSubmittedAssignments
}

export const getExamsDates = (cohort, exams) => {
  if (!cohort || !exams?.length) return {}

  const hasFinalExam = !isCourseWithoutFinalExam(cohort.courseId)
  const dates = exams.map((exam, index) => {
    let unlockTime = null
    let lockTime = null

    if (hasFinalExam && index === exams.length - 1) {
      unlockTime = cohort.finalExamStartTime
      lockTime = cohort.finalExamEndTime
    } else if (index < 2) {
      unlockTime = cohort[`midTerm${index + 1}StartTime`]
      lockTime = cohort[`midTerm${index + 1}EndTime`]
    } else if (index < exams.length - 1) {
      unlockTime = cohort[`exam${index + 1}StartTime`]
      lockTime = cohort[`exam${index + 1}EndTime`]
    }

    return {
      uuid: exam?.chapter_uuid || '',
      examNumber: index + 1,
      unlockTime,
      lockTime
    }
  })
  return dates
}

export const getSingleExamDates = (examsDates, examUUID) => {
  if (!examsDates?.length || !examUUID) return null
  return examsDates.find(dates => dates.uuid === examUUID)
}

export const isExamOpen = (unlockTime, lockTime) => {
  const unlockDate = new Date(unlockTime)
  const lockDate = new Date(lockTime)
  const unValidDate = unlockDate.toString() === 'Invalid Date' ||
    lockDate.toString() === 'Invalid Date'
  if (unValidDate) return
  const currentTime = secondsSinceEpoch()
  const unlockTimeInSeconds = dateToSecondsSinceEpoch(new Date(unlockTime))
  const lockTimeInSeconds = dateToSecondsSinceEpoch(new Date(lockTime))

  return (!unlockTimeInSeconds || currentTime > unlockTimeInSeconds) &&
    currentTime < lockTimeInSeconds
}

export const getNumberAndUnitsLists = () => {
  const unitsList = [
    { label: 'Days', value: 'days' },
    { label: 'Weeks', value: 'weeks' },
    { label: 'Months', value: 'months' }
  ]
  const numbersList = range(1, 31, 1)
    .map((number) => { return { label: number, value: number } })

  return { numbersList, unitsList }
}

export const getFormattedShortDateAndTime = (stringDate) => {
  const date = new Date(stringDate)
  if (date.toString() === 'Invalid Date') return

  const formattedDate = getDateString(new Date(date), 'MM/DD/YY')
  const formattedTime = new Date(date)
    .toLocaleTimeString(
      [],
      { hour: '2-digit', minute: '2-digit'
      })
    .toLowerCase()

  return [formattedDate, formattedTime]
}

export const getAssessmentNewDeadline = (deadline, numberOfUnit, unit) => {
  let assessmentNewDeadlineDate
  switch (unit) {
    case 'days':
      assessmentNewDeadlineDate = getDateAfterDays(deadline, numberOfUnit)
      break
    case 'weeks':
      assessmentNewDeadlineDate = getDateAfterWeeks(deadline, numberOfUnit)
      break
    case 'months':
      assessmentNewDeadlineDate = addMonthsToDate(deadline, numberOfUnit)
      break
    // no default
  }
  return assessmentNewDeadlineDate
}

export const getAttemptId = (cohort, course) => {
  if (!cohort || !course) return
  const { id: cohortId } = cohort
  const { statusData } = course
  const { attemptID } = statusData.find(cohort => cohort.id === cohortId)
  return attemptID
}

export const getExamKey = (examNumber, numberOfExams, hasFinalExam) => {
  if (!examNumber || !numberOfExams) return ''

  if (hasFinalExam && examNumber === numberOfExams) return 'finalDeadlineExt'
  if (examNumber < 3) return `midterm${examNumber}DeadlineExt`
  return `exam${examNumber}DeadlineExt`
}

export const getAssessmentsToExtend = ({
  numberOfExams,
  hasFinalExam,
  assessments,
  selectedNumber,
  selectedUnitLength
}) => {
  if (!assessments || assessments.length < 1 ||
    !selectedUnitLength || !selectedNumber
  ) return

  const examsToExtend = {}
  const assignmentsToExtend = {}
  assessments.forEach((assessment) => {
    const { examNumber, assessmentName } = assessment
    const { value: numberOfUnit } = selectedNumber
    const { value: unit } = selectedUnitLength
    const assessmentKey = assessmentName || getExamKey(examNumber, numberOfExams, hasFinalExam)
    const { lockTime: deadline } = assessment
    const assignmentNewDeadlineDate = getAssessmentNewDeadline(
      deadline,
      numberOfUnit,
      unit
    )
    const formattedDeadline = moment(assignmentNewDeadlineDate).format()
    if (assessmentName) {
      assignmentsToExtend[assessmentKey] = formattedDeadline
      return
    }
    examsToExtend[assessmentKey] = formattedDeadline
  })

  return {
    examDeadlines: examsToExtend,
    milestoneDeadlines: assignmentsToExtend
  }
}

export const getSingleAssessmentToExtend = ({
  assessment, newDeadlineDate, numberOfExams, hasFinalExam
}) => {
  if (!assessment || !newDeadlineDate) return
  const { examNumber, assessmentName } = assessment
  const formattedDeadline = moment(newDeadlineDate).format()
  const assessmentKey = assessmentName || getExamKey(examNumber, numberOfExams, hasFinalExam)
  const assessmentsKey = assessmentName
    ? 'milestoneDeadlines'
    : 'examDeadlines'
  return {
    [assessmentsKey]: {
      [assessmentKey]: formattedDeadline
    }
  }
}
