export const DATE_FIELDS = [
  'recordlastmodified',
  'courseendtime_from_cohort',
  'finalexamendtime_from_cohort',
  'consentTime',
  'unlockTime',
  'lockTime',
  'dateStart',
  'finalGradeDate',
  'finalRegistrationDate',
  'finalWithdrawalDate',
  'midterm1StartTime',
  'midterm1EndTime',
  'midterm2StartTime',
  'midterm2EndTime',
  'finalExamStartTime',
  'finalExamEndTime',
  'exam3StartTime',
  'exam3EndTime',
  'exam4StartTime',
  'exam4EndTime',
  'exam5StartTime',
  'exam5EndTime',
  'finalDropDate',
  'dateGradesSubmitted',
  'updatedAt',
  'eventsUpdatedAt',
  'updatedAt',
  'lastUpdated',
  'midterm1Deadline',
  'midterm2Deadline',
  'finalDeadline',
  'created',
  'updatedat',
  'originalcohortenddate',
  'midterm1DeadlineExt',
  'midterm2DeadlineExt',
  'exam4DeadlineExt',
  'exam5DeadlineExt',
  'milestoneDeadline',
  'milestoneDeadlineExt',
  'finalDeadlineExt',
  'midterm1RetakeStart',
  'midterm1Deadline',
  'midterm2RetakeStart',
  'midterm2Deadline',
  'finalRetakeStart',
  'finalDeadline',
  'exam3RetakeStart',
  'exam3RetakeDeadline',
  'exam4RetakeStart',
  'exam4RetakeDeadline',
  'exam5RetakeStart',
  'exam5RetakeDeadline',
  'milestoneRetakeStart',
  'milestoneDeadline',
  'lastUpdated',
  'openEnrollment',
  'closeEnrollment',
  'semesterStart',
  'last_modified',
  'Created',
  'Created',
  'updatedAt',
  'dateOfBirth',
  'last_applicationSubmitted_update',
  'thirdPartyDate',
  'degreeApplicationSubmission',
  'signatureDate',
  'lastModifiedDate',
  'tempCartUpdatedAt',
  'guardianSignatureDate',
  'updatedAt',
  'dateStart_from_cohort',
  'dateStart_from_cohorts',
  'purchaseDate',
  'latestRefundDate',
  'finalDropDate_from_cohorts',
  'lastUpdatedAt',
  'createdTime',
  'relationshipStartDate',
  'tokenExpirationDate',
  'lastUpdatedAt',
  'dayStart',
  'dayEnd',
  'guardianTOSDate',
  'lastUpdated',
  'updatedAt',
  'dateSubscriptionCancelled',
  'dateEligibilityLost',
  'guildUpdatedAt',
  'dateOfBirth',
  'dateTOS',
  'dateToRemoveAccess',
  'passwordResetDate',
  'dateDOBChanged',
  'createdOn_from_tuitionVoucher',
  'tokenIssueDate',
  'tokenExpirationDate',
  'lastUpdatedAt',
  'dateStart',
  'voucherUploadDate',
  'dateEnd',
  'activeDate',
  'expirationDate',
  'submissionTime',
  'submissionTime',
  'updatedAt',
  'submissionTime',
  'updatedAt',
  'submissionTime'
]
