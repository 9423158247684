import React, { useReducer } from 'react'
import api from '../api'
import {
  SET_COURSES,
  SET_CURRENT_COHORT,
  SET_CURRENT_COURSE,
  SET_EDIT_GRADES_RELEVANT_DATA,
  SET_GRADE_REPORT_DATA,
  SET_IS_COHORT_END,
  SET_SECTION_DATA,
  SET_STUDENT_STATUS,
  SET_SEARCHED_EMAILS,
  SET_SELECTED_STUDENT_COHORT,
  SET_GRADE_RANGE,
  SET_CODING_ASSIGNMENT,
  SET_SORT_HISTORY_DATA,
  SET_TABLE_DATA,
  SET_MAX_PARTICIPATION_GRADE
} from '../Constants/actionTypes'
import {
  getCohortByName,
  getCourseByName,
  isCohortCompleted
} from '../utilities/courseUtils'
import {
  getGradeReport,
  getStudentProgress,
  mergeGradeReportWithCurrentGrades
} from '../utilities/gradeReportUtils'
import { getMaxCourseParticipationGrade } from '../courseConfig'

const StudentGradeContext = React.createContext()

const initialState = {
  cohortsEndInfo: {},
  courses: [],
  gradeReport: [],
  changesHistory: [],
  codingAssignment: null,
  globalSectionData: [],
  currentCourse: { name: '', id: '' },
  currentCohort: '',
  endingGradeRange: '',
  startingGradeRange: '',
  selectedStatus: [],
  selectedStudentCohort: '',
  searchedEmails: '',
  gradeData: {},
  courseData: {},
  sectionOverviewData: [],
  studentProgress: {},
  historyData: [],
  maxParticipationGrade: 5
}

export function reducer (state, action) {
  switch (action.type) {
    case SET_SORT_HISTORY_DATA:
      return {
        ...state,
        changesHistory: action.changesHistory
      }
    case SET_SECTION_DATA:
      return {
        ...state,
        globalSectionData: action.globalSectionData
      }
    case SET_GRADE_REPORT_DATA:
      return {
        ...state,
        gradeReport: action.gradeReport
      }
    case SET_TABLE_DATA: {
      const {
        gradeData, courseData, syllabus, sectionOverviewData, studentProgress, historyData
      } = action
      return {
        ...state,
        gradeData,
        courseData,
        syllabus,
        sectionOverviewData,
        studentProgress,
        historyData,
        globalSectionData: []
      }
    }
    case SET_EDIT_GRADES_RELEVANT_DATA: {
      const { courseData, studentProgress } = action
      return {
        ...state,
        courseData,
        studentProgress
      }
    }
    case SET_COURSES: {
      const { courses } = action
      return {
        ...state,
        courses
      }
    }

    case SET_IS_COHORT_END: {
      const { cohortInfo: { name, isEnd } } = action
      return {
        ...state,
        cohortsEndInfo: {
          ...state.cohortsEndInfo,
          [name]: isEnd
        }
      }
    }

    case SET_CURRENT_COURSE: {
      return {
        ...state,
        currentCourse: action.currentCourse
      }
    }

    case SET_SELECTED_STUDENT_COHORT: {
      return {
        ...state,
        selectedStudentCohort: action.selectedStudentCohort
      }
    }

    case SET_CURRENT_COHORT: {
      return {
        ...state,
        currentCohort: action.currentCohort
      }
    }

    case SET_STUDENT_STATUS: {
      return {
        ...state,
        selectedStatus: action.selectedStatus
      }
    }
    case SET_SEARCHED_EMAILS: {
      return {
        ...state,
        searchedEmails: action.searchedEmails
      }
    }

    case SET_GRADE_RANGE: {
      return {
        ...state,
        startingGradeRange: action.startingGradeRange,
        endingGradeRange: action.endingGradeRange
      }
    }

    case SET_CODING_ASSIGNMENT: {
      return {
        ...state,
        codingAssignment: action.codingAssignment
      }
    }

    case SET_MAX_PARTICIPATION_GRADE: {
      return {
        ...state,
        maxParticipationGrade: action.maxParticipationGrade
      }
    }

    default:
      return new Error()
  }
}
// eslint-disable-next-line
export default function StudentGradeProvider({ children, providedState }) {
  const [state, dispatch] = useReducer(reducer, providedState || initialState)
  const fetchGradeReport = async ({
    courseId,
    cohortName
  }) => {
    const gradeReport = await getGradeReport({
      courseId,
      queryParams: `cohortName=${encodeURIComponent(cohortName)}&fetchAttemptDetails=true`
    })
    dispatch({ type: SET_GRADE_REPORT_DATA, gradeReport })
    const currentGrades = await api.getStudentsCurrentGrade(courseId, cohortName)
    return mergeGradeReportWithCurrentGrades(
      gradeReport,
      currentGrades
    )
  }

  const fetchTableData = async ({
    courseId,
    studentEmail,
    cohortId,
    cohortName
  }) => {
    const { gradeReport } = state
    const gradeDataFromGradeReport = gradeReport.find(
      userGrade => userGrade.email === studentEmail
    )

    let gradeData = gradeDataFromGradeReport
    if (!gradeData) {
      gradeData = await getGradeReport({
        courseId,
        cohortId,
        studentEmail,
        queryParams: `studentEmail=${encodeURIComponent(studentEmail)}&cohortName=${cohortName}`
      })
      const currentGrades = await api.getStudentsCurrentGrade(courseId, cohortName)
      const mergedGradeReport = mergeGradeReportWithCurrentGrades(
        gradeData,
        currentGrades
      )
      gradeData = mergedGradeReport
    }

    const [sectionOverviewData, courseData, syllabus, studentProgress, historyData] = await Promise.all(
      [
        getGradeReport({
          courseId,
          cohortId,
          studentEmail,
          populateQuizzes: true,
          queryParams: `cohortName=${cohortName}&populateQuizzes=true`
        }),
        api.getCourseData(courseId),
        api.getSyllabusData(cohortId),
        getStudentProgress({
          courseId,
          cohortId,
          isCurrentCohort: !gradeData?.pastCohortData,
          studentEmail }),
        api.getAllChangesHistory(courseId, studentEmail)
      ])

    dispatch({
      type: SET_TABLE_DATA,
      gradeData,
      syllabus,
      sectionOverviewData,
      courseData,
      studentProgress,
      historyData
    })
  }

  const fetchEditGradesRelevantData = async ({
    courseId,
    cohortId,
    studentEmail }) => {
    const [courseData, studentProgress] = await Promise.all(
      [
        api.getCourseData(courseId),
        getStudentProgress({
          courseId,
          cohortId,
          isCurrentCohort: true,
          studentEmail
        })
      ])
    dispatch({
      type: SET_EDIT_GRADES_RELEVANT_DATA,
      courseData,
      studentProgress
    })
  }

  const fetchSectionData = async (courseId, sectionUuid) => {
    const { globalSectionData } = state
    const sectionData = await api.loadSectionData(courseId, sectionUuid)
    sectionData.course_uuid = courseId
    dispatch({
      type: SET_SECTION_DATA,
      globalSectionData: [...globalSectionData, sectionData]
    })
    return sectionData
  }

  const setChangesHistory = async (changesHistory) => {
    dispatch({ type: SET_SORT_HISTORY_DATA, changesHistory })
  }

  const setCurrentCourse = async (currentCourse) => {
    dispatch({ type: SET_CURRENT_COURSE, currentCourse })
  }

  const setSelectedStudentCohort = (selectedStudentCohort) => {
    dispatch({ type: SET_SELECTED_STUDENT_COHORT, selectedStudentCohort })
  }

  const setCurrentCohort = async (currentCohort) => {
    dispatch({ type: SET_CURRENT_COHORT, currentCohort })
  }

  const fetchCourses = async () => {
    if (state.courses.length) return
    const courses = await api.getCourses()
    dispatch({ type: SET_COURSES, courses })
  }

  const dispatchIsCohortEnd = (cohortName, isEnd) => {
    return dispatch({
      type: SET_IS_COHORT_END,
      cohortInfo: {
        name: cohortName,
        isEnd
      }
    })
  }

  const setIsCohortEnd = async (user, courseName, cohortName) => {
    const { courses } = await api.getStudentCourses({ studentEmail: user.email })
    const currentCourse = getCourseByName(courses, courseName)
    if (!currentCourse) {
      dispatchIsCohortEnd(cohortName, false)
      return false
    }

    const cohort = getCohortByName(currentCourse.statusData, cohortName)
    setSelectedStudentCohort(cohort)
    if (!cohort || !cohort.dateStart) {
      dispatchIsCohortEnd(cohortName, false)
      return false
    }

    const isEnd = isCohortCompleted(cohort, new Date())
    dispatchIsCohortEnd(cohortName, isEnd)
    return isEnd
  }

  const setSelectedStatus = (selectedStatus) => {
    return dispatch({
      type: SET_STUDENT_STATUS,
      selectedStatus
    })
  }

  const setSearchedEmails = (searchedEmails) => {
    return dispatch({
      type: SET_SEARCHED_EMAILS,
      searchedEmails })
  }

  const setGradeRange = (startingGradeRange, endingGradeRange) => {
    return dispatch({
      type: SET_GRADE_RANGE,
      startingGradeRange,
      endingGradeRange
    })
  }

  const setCodingAssignment = (codingAssignment) => {
    return dispatch({
      type: SET_CODING_ASSIGNMENT,
      codingAssignment
    })
  }

  const getMaxParticipationGrade = async (courseId, cohortStartDate) => {
    const maxParticipationGrade =
      (await getMaxCourseParticipationGrade(courseId, cohortStartDate)) || 5
    dispatch({
      type: SET_MAX_PARTICIPATION_GRADE,
      maxParticipationGrade
    })
    return maxParticipationGrade
  }

  const actions = {
    setCurrentCourse,
    setCurrentCohort,
    setIsCohortEnd,
    fetchCourses,
    setGradeRange,
    setChangesHistory,
    fetchSectionData,
    setSearchedEmails,
    fetchTableData,
    setSelectedStatus,
    setCodingAssignment,
    fetchGradeReport,
    fetchEditGradesRelevantData,
    getMaxParticipationGrade
  }

  return (
    <StudentGradeContext.Provider value={{ state, actions }}>
      {children}
    </StudentGradeContext.Provider>
  )
}

export function useStudentGradeContext () {
  return React.useContext(StudentGradeContext).state
}

export function useStudentGradeActions () {
  return React.useContext(StudentGradeContext).actions
}
