import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import {
  DropDownWrapper,
  FormLabel,
  FormRow,
  FormWrapper, SelectLabel
} from './index.styled'
import CustomSelect from '../../Components/CustomSelect/UpdatedSelect'
import {
  FormInput
} from '../../Components/EnrollStudentsTool/style'
import {
  DropdownIndicator
} from '../../Components/CustomSelect/CustomSelectComponents'
import {
  getActiveCohortsOptions
} from '../../utilities/cohortUtils'
import api from '../../api'
import { getActiveCoursesByRelationship } from '../../utilities/courseUtils'
import { getSortedCourses } from '../../utilities/gradeReportUtils'

const CourseSelector = ({
  isSelectedRelationshipEmpty,
  selectedRelationship,
  selectedCourse,
  selectedCohort,
  isLockedEmpty,
  setSelectedCourse,
  setSelectedCohort
}) => {
  const [allCourses, setAllCourses] = useState([])
  const [allCohorts, setAllCohorts] = useState([])
  const [isCohortLoading, setIsCohortLoading] = useState(false)
  const [isCourseLoading, setIsCourseLoading] = useState(false)

  useEffect(() => {
    if (allCohorts.length) return
    const fetchCohorts = async () => {
      setIsCohortLoading(true)
      const cohorts = await api.getAllCohorts()
      setAllCohorts(cohorts)
      setIsCohortLoading(false)
    }

    fetchCohorts()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (allCourses.length) return
    const fetchCourses = async () => {
      setIsCourseLoading(true)
      const courses = await api.getCourses()
      setAllCourses(courses)
      setIsCourseLoading(false)
    }

    fetchCourses()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    setSelectedCohort(null)
    // eslint-disable-next-line
  }, [selectedCourse])

  useEffect(() => {
    setSelectedCourse(null)
    // eslint-disable-next-line
  }, [selectedRelationship])

  const filteredCourses = useMemo(() => {
    if (!selectedRelationship || isSelectedRelationshipEmpty) return []
    const activeCourses = getActiveCoursesByRelationship(
      allCourses,
      selectedRelationship
    )

    return getSortedCourses(activeCourses)
    // eslint-disable-next-line
  }, [selectedRelationship])

  const activeCohorts = useMemo(() => {
    if (!selectedCourse || !allCohorts?.length) return []
    return getActiveCohortsOptions(allCohorts, selectedCourse.at_id)
  }, [selectedCourse, allCohorts])

  const handleCourseChange = useCallback((selectedOption) => {
    setSelectedCourse(selectedOption)
    selectedCohort && setSelectedCohort(null)
  }, [setSelectedCourse, setSelectedCohort, selectedCohort])

  const handleCohortChange = useCallback((selectedOption) => {
    setSelectedCohort(selectedOption)
  }, [setSelectedCohort])

  return (
    <>
      <FormLabel mb='12px'>Please select a course</FormLabel>
      <FormWrapper gap='24px'>
        <FormRow>
          <DropDownWrapper>
            <SelectLabel htmlFor='courses'>Course</SelectLabel>
            {isLockedEmpty ? (
              <FormInput
                data-testid='disabled-course-dropdown'
                disabled
                style={{ width: '400px', marginBottom: '0' }}
              />
            ) : (
              <CustomSelect
                isClearable
                isLoading={isCourseLoading}
                id='courses'
                options={filteredCourses}
                name='courses'
                data-testid='course-dropdown'
                placeholder='Select a course'
                width={'400px'}
                components={{ DropdownIndicator }}
                onChange={handleCourseChange}
                value={selectedCourse}
              />
            )}
          </DropDownWrapper>
        </FormRow>
        <FormRow>
          <DropDownWrapper>
            <SelectLabel htmlFor='cohorts'>Cohort</SelectLabel>
            {!selectedCourse ? (
              <FormInput
                data-testid='disabled-cohorts-dropdown'
                disabled
                style={{ width: '250px', marginBottom: '0' }}
              />
            ) : (
              <CustomSelect
                isClearable
                isDisabled={!selectedCourse}
                isLoading={isCohortLoading}
                id='cohorts'
                options={activeCohorts}
                name='cohorts'
                data-testid='cohort-dropdown'
                placeholder='Select a cohort'
                width={'250px'}
                components={{ DropdownIndicator }}
                onChange={handleCohortChange}
                value={selectedCohort}
              />
            )}
          </DropDownWrapper>
        </FormRow>
      </FormWrapper>
    </>
  )
}

CourseSelector.propTypes = {
  setSelectedCourse: PropTypes.func.isRequired,
  setSelectedCohort: PropTypes.func.isRequired,
  isLockedEmpty: PropTypes.bool.isRequired
}
export default CourseSelector
