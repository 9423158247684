import styled, { css } from 'styled-components'
import { Input } from 'reactstrap'
import trashIcon from '../../../assets/icons/trash-icon.svg'
import { ReactComponent as WarningIconSVG } from '../../../assets/icons/warning.svg'
import mediaqueries from '../../../mediaQueries'

export const DatabaseViewerContainer = styled.div`
  padding: 24px 42px 0 24px;
  isolation: isolate;
  display: flex;
  flex-direction: column;
  height: 100%;

  ${mediaqueries.smallMobile`
      padding-top:32px;
    `}

  & * {
    --sb-track-color: transparent;
    --sb-thumb-color: #5b6067;
    --sb-size: 18px;

    scrollbar-color: var(--sb-thumb-color) var(--sb-track-color);

    &::-webkit-scrollbar {
      width: var(--sb-size);
      height: var(--sb-size);
    }

    &::-webkit-scrollbar-track {
      background: var(--sb-track-color);
      border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--sb-thumb-color);
      border-radius: 10px;
      border: 5px solid var(--sb-track-color);
      background-clip: content-box;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #98a4ae;
      border-radius: 10px;
      border: 5px solid var(--sb-track-color);
      background-clip: content-box;
    }

    &::-webkit-scrollbar-corner {
      background: transparent;
    }
  }
`

export const DropDownLabel = styled.span`
  color: #B1BFC5;
  font-size: 16px;
  line-height: 19.2px;
  font-weight: 400;
`
export const Header = styled.h2`
  margin-top: 23px;
  font-weight: 300;
  font-size: 32px;
  line-height: 38px;
  text-transform: uppercase;
`

export const AddRecordButton = styled.span`
  float: right;
  margin-left: 10px;
`

export const Separator = styled.hr`
  margin: 24px 0;
  height: 0;
  border: 0.5px solid rgba(255, 255, 255, 0.3);
`

export const TableContainer = styled.div`
  min-width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: auto;

  border: 1px solid #5b6067;
  border-left-width: 0.5px;
  border-top-width: 0.5px;
  border-radius: 5px;

  & .bodyCell {
    padding: 6px 12px;
    line-height: normal;
    font-size: 14px;
    display: flex;
    align-items: center;
  }

  & .bodyCell:has(.linked-records) {
    padding-right: 0;
  }

  & .headerColumn {
    color: var(--sub-text-secondary);
    font-size: 16px;
    padding: 12px 24px;
  }

  & .headerColumn div span {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  & .headerColumn:first-of-type {
    border-top-left-radius: 4px;
    padding: 0;
  }

  & .headerColumn:nth-child(2),
  & .headerColumn:nth-child(1) {
    position: sticky !important;
  }

  & .table-responsive .tableBodyRow:hover > td,
  .table-responsive .tableBodyRow:hover {
    background-color: #111113 !important;
  }

  &::-webkit-scrollbar-track:vertical {
    border-left: 1px solid #5b6067;
    border-top: 0.5px solid #5b6067;
    border-radius: 0 4px 0 0;
  }

  &::-webkit-scrollbar-track:horizontal {
    border-top: 1px solid #5b6067;
    border-left: 0.5px solid #5b6067;
    border-radius: 0 0 0 4px;
  }
`

export const ModalContents = styled.div`
  padding: 24px 27px;
  background: var(--black-secondary);
  height: 56vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 24px;
`
export const ModalContentsWrapper = styled.div`
  position: relative;
  flex-grow: 1;
`

export const ModalContentsUpdate = styled.div`
  position: absolute;
  width: 100%;
  flex-grow: 1;
  padding: 24px 24px 36px;
  background: var(--black-secondary);
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 24px;

  & {

    &::-webkit-scrollbar {
      width: 8px !important;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--border-secondary);
      border-radius: 10px;
      visibility: visible;
      height: 25% !important;
    }
  }
`

export const FormContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
`

export const FilterFormWrapper = styled.div`
  border: 1px solid var(--border-primary);
  border-radius: 5px;
  margin: 20px 0;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const FormLabel = styled.label`
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #FFFFFF;
  margin-bottom: 0;
  display: block;
  width: 200px;
  word-wrap: break-word;
`

export const InputContainer = styled.div`
  height: auto;
  width: 300px;
  margin-left: auto;
  .custom-checkbox {
    margin-bottom: 19px;
  }
  .select {
    margin-bottom: 0;
  }
`

export const FormInput = styled(Input)`
  background: var(--black-tertiary);
  border: ${({ error }) =>
    error ? '1px solid #FF8064' : '1px solid var(--border-primary)'};
  box-sizing: border-box;
  border-radius: 5px;
  padding: 16px;
  color: #B1BFC5;
  font-size: 16px;
  line-height:24px;
  height: auto;
  width: 100%;
  
  &:hover {
    outline: none;
    background: var(--black-tertiary);
    color: #B1BFC5;
    border: ${({ error }) => error ? '1px solid #FF8064' : '1px solid var(--primary-color)'};
    box-shadow: none;
  }
  
  &:focus {
    outline: none;
    color: #B1BFC5;
    background: var(--black-tertiary);
    border: ${({ error }) => error ? '1px solid #FF8064' : '1px solid var(--text-primary)'};
    box-shadow: none;
  }
  &:disabled {
    background: var(--black-tertiary);
  }
`

export const FilterInput = styled(FormInput)`
  margin-top: 7px;
  height: 56px;
  width: 350px;
`

export const placeholderStyles = {
  color: '#5B6067',
  fontSize: '16px',
  lineHeight: '24px'
}

export const ContextMenu = styled.div`
  position: absolute;
  min-width: 200px;
  background-color: var(--gray-primary);
  border-radius: 5px;
  box-sizing: border-box;
  padding: 0;
  z-index: 9;
  ${({ top, left }) => css`
    top: ${top}px;
    left: ${left}px;
  `}
  ul {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.5);
  }
  ul li {
    padding: 16px;
    border-radius: 5px;
    :before {
      content: '';
      display: inline-block;
      width: 14px;
      height: 16px;
      margin-right: 9px;
      background-image: url(${trashIcon});
      vertical-align: middle;
    }
  }
  /* hover */
  ul li:hover {
    cursor: pointer;
    background-color: var(--gray-secondary);
  }
`

export const ConfirmationMessage = styled.div`
  background: var(--black-secondary);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  color: #B1BFC5;
  p {
    margin-bottom: 24px;
  }
`

export const WarningIcon = styled(WarningIconSVG)`
  display: inline-block;
  margin-top: -4px;
  margin-right: 12px;
`

export const WarningMessage = styled.div`
  background: var(--black-secondary);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  color: #FFC107;
`

export const ModalContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${props => props.position === 'top' ? 'flex-start' : 'center'};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: ${props => props.show ? 1 : 0};
  z-index: ${props => props.show ? 1000 : -1};
  transition: opacity 0.5s ease-in-out;
  background-color: rgba(0, 0, 0, 0.5);
`

export const ModalWrapper = styled.div`
  background: var(--black-secondary);
  box-shadow: 0px 6px 20px var(--shadow-primary);
  border-radius: 5px;
  overflow: hidden;
  width: 929px;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 48px 0;

  ${mediaqueries.smallMobile`
    width: calc(100% - 48px);
  `}
`

export const ModalHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 24px;
  background: var(--gray-primary);
`

export const ContentWrapper = styled.div`
  display: flex;
  gap: 6px;
  height: 100%;
`

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`

export const CancelButton = styled.span`
  color: var(--brand-regular);
  text-transform: uppercase;
  width: 76px;
  height: 32px;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    color: #FFFFFF;
  }
`

export const AddButton = styled.span`
  text-transform: uppercase;
  width: 117px;
  height: 32px;
  padding: 8px;
  border-radius: 5px;
  gap: 6px;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 1px;
  text-align: center;
  color: var(--text-dark);
  background-color: var(--brand-regular);
  cursor: pointer;
`

export const ToggleHiddenFieldsContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 24px;
  align-items: center;
  margin-top: 16px;

  & > hr {
    width: 100%;
    margin: 0;
    background-color: var(--border-secondary);
  }
`
export const ToggleHiddenFieldsDropdown = styled.button`
  display: flex;
  gap: 8px;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1px;
  text-transform: uppercase;
  background-color: transparent;
  border: none;
  align-items: center;
  justify-content: center;
  padding: 0;
  white-space: nowrap;

  & > svg {
    ${(props) => props.isHiddenFieldsVisible && `transform: rotate(180deg);`}
  }

  &:focus {
    outline: none;
  }
  &:focus-visible {
    outline: 5px auto -webkit-focus-ring-color;
  }
`
export const HideFieldsContainer = styled.div`
  display: ${(props) => (props.isHiddenFieldsVisible ? 'flex' : 'none')};
  flex-direction: column;
  gap: 24px;
`
