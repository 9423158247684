import React, { memo, useEffect } from 'react'
import {
  AnswerStatus,
  AnswerText,
  AnswerTitle,
  CardOption,
  CardOptionContainer,
  CardOptionIcon,
  CardOptionsContainer,
  CardOptionText,
  CardPreviewContainer,
  CardTitle,
  StudentCorrectIcon,
  CorrectAnswerText,
  IncorrectAnswerIcon,
  UnselectedAnswerIcon,
  CorrectAnswer,
  PreviewText,
  CardText,
  Separator
} from './CardPreview.styled'
import { escapeRegExp, isArray, isEmpty, isString } from 'lodash'
import AttributedImage from '../AttributedImage/AttributedImage'

import { getCorrectAnswer, replaceBlanksWithInputElem } from './utils'
import { TEXT_BLANKS } from './utils/constants'

const CardPreview = ({ question, studentProgress }) => {
  let {
    title,
    cmsTitle,
    Question_uuid: questionUUID,
    options,
    answer,
    text_when_correct: textWhenCorrect,
    text_when_incorrect: textWhenIncorrect,
    question_text: questionText,
    lesson_text: lessonText,
    question_type: questionType,
    configuration
  } = question || {}

  const { studentAnswers } = studentProgress || {}

  const isTextBlanksQuestion = questionType === TEXT_BLANKS

  const isMathJaxText = (text) => isString(text) && text.includes('<math')

  const isCorrect = studentAnswers?.[questionUUID]?.correct
  let studentAnswer = studentAnswers?.[questionUUID]?.answer

  studentAnswer = isArray(studentAnswer) ? studentAnswer : [studentAnswer]

  let correctAnswer = isTextBlanksQuestion
    ? answer
    : answer?.map((answer) => parseInt(answer))

  correctAnswer = isEmpty(correctAnswer)
    ? isMathJaxText(configuration)
      ? [getCorrectAnswer(configuration)]
      : answer
    : correctAnswer

  const displayTitle = title || cmsTitle?.match(/(Question\s\d+)/)?.[0]

  useEffect(() => {
    if (!isTextBlanksQuestion) return
    const fillTheBlanks = () => {
      const blank = document.getElementsByName('blank[]')
      const {
        answer,
        fitbCaseSensitive
      } = question || {}
      if (Array.isArray(studentAnswer)) {
        const studentAnswerStr = studentAnswer.map(String)
        if (blank.length > 0) {
          blank.forEach((element, key) => {
            if (Array.isArray(answer) && answer[key] && studentAnswerStr[key]) {
              element.value = studentAnswerStr[key]
              const isSameAns = fitbCaseSensitive
                ? answer[key] === studentAnswerStr[key]
                : answer[key].toLowerCase() === studentAnswerStr[key].toLowerCase()
              const isTextMatched = fitbCaseSensitive
                ? escapeRegExp(answer[key]).match(escapeRegExp(studentAnswerStr[key]))
                : escapeRegExp(answer[key].toLowerCase())
                  .match(escapeRegExp(studentAnswerStr[key].toLowerCase()))
              element.style.color = isCorrect || isSameAns
                ? '#78BCB8'
                : isTextMatched ? '#FFF' : '#E1774F'
              element.style.borderBottom = isTextMatched || isCorrect
                ? '1px solid #78BCB8'
                : '1px solid #E1774F'
            }
          })
        }
      }
    }

    fillTheBlanks()
    // eslint-disable-next-line
  }, [])

  if (isTextBlanksQuestion) {
    questionText = replaceBlanksWithInputElem(question, questionText)
  }

  const getIllustrationAlt = () => {
    const {
      illustration_alt: illustrationAlt,
      illustrationProps
    } = question

    if (illustrationAlt) return illustrationAlt

    if (!illustrationProps) return null
    const { alt } = illustrationProps

    return alt
  }

  const getIllustrationTitle = () => {
    const {
      illustration_title: illustrationTitle,
      illustrationProps
    } = question

    if (!illustrationProps) return illustrationTitle
    const { title } = illustrationProps

    return title || illustrationTitle
  }

  const displayIllustration = () => {
    const {
      illustration,
      illustrationProps,
      question_type: questionType
    } = question || {}
    if (!illustration || !illustrationProps) return
    const illustrationStr = typeof illustration === 'object'
      ? illustration.url?.toString()
      : illustration.toString()
    if (!illustrationStr) return

    const illustrationAlt = getIllustrationAlt()
    const illustrationTitle = getIllustrationTitle()

    return (
      <div className={questionType === 'card'
        ? 'col-12 mb-5'
        : 'col-12 mb-5 col-lg-6'}
      >
        <div width='100%'>
          <AttributedImage alt={illustrationAlt} src={illustrationStr} title={illustrationTitle} />
        </div>
      </div>
    )
  }

  const displayCardText = (questionText) => {
    if (!questionText) return null

    return (
      <CardText
        dangerouslySetInnerHTML={{ __html: questionText }}
        data-testid='card-text'
      />
    )
  }

  return (
    <CardPreviewContainer data-testid='card-preview'>
      <PreviewText>
          Preview
      </PreviewText>
      <CardTitle>
        {isCorrect ? <StudentCorrectIcon /> : <IncorrectAnswerIcon />}
        {displayTitle}
      </CardTitle>
      {lessonText &&
        <>
          {displayCardText(lessonText)}
          <Separator />
        </>}
      <div className='row'>
        {displayIllustration()}
        <div className='col'>
          {displayCardText(questionText)}
        </div>
      </div>
      <CardOptionsContainer>
        {options?.map((option, index) => {
          const isOptionCorrect =
            studentAnswer.includes(index) && correctAnswer.includes(index + 1)
          const isOptionSelected =
            studentAnswer.includes(index) && !isOptionCorrect
          const isTheCorrectAnswer = correctAnswer.includes(index + 1)
          return (
            <CardOptionContainer key={index}>
              <CardOption
                isOptionCorrect={isOptionCorrect}
                isOptionSelected={isOptionSelected}
                isTheCorrectAnswer={isTheCorrectAnswer}
              >
                <CardOptionText>
                  {isOptionCorrect && (
                    <CorrectAnswerText>Correct Answer</CorrectAnswerText>
                  )}
                  <span dangerouslySetInnerHTML={{ __html: option }} />
                </CardOptionText>
              </CardOption>
              <CardOptionIcon>
                {isOptionCorrect
                  ? (<StudentCorrectIcon />)
                  : isOptionSelected
                    ? (<IncorrectAnswerIcon />)
                    : isTheCorrectAnswer ? <CorrectAnswer /> : <UnselectedAnswerIcon />}
              </CardOptionIcon>
            </CardOptionContainer>
          )
        })}
      </CardOptionsContainer>
      {isCorrect && (
        <>
          <AnswerStatus>
            <StudentCorrectIcon />
            <span>Correct</span>
          </AnswerStatus>
          {displayCardText(textWhenCorrect)}
        </>
      )}
      {!isCorrect && (
        <>
          <AnswerStatus>
            <IncorrectAnswerIcon />
            <span>Incorrect</span>
          </AnswerStatus>
          <div className='mb-4'>
            <AnswerTitle>Your answer</AnswerTitle>
            {studentAnswer?.map((answer, index) => {
              const answerText = isTextBlanksQuestion
                ? answer
                : options?.length > 0 ? options?.[answer] : answer
              return (
                <AnswerText key={index}>
                  {displayCardText(answerText)}
                </AnswerText>
              )
            })}
          </div>
          <div className='mb-4'>
            <AnswerTitle>Correct answer</AnswerTitle>
            {correctAnswer?.map((answer, index) => {
              const answerText = isTextBlanksQuestion
                ? answer
                : options.length > 0 ? options?.[answer - 1] : answer
              return (
                <AnswerText key={index}>
                  {displayCardText(answerText)}
                </AnswerText>
              )
            })}
          </div>
          {isArray(textWhenIncorrect)
            ? textWhenIncorrect?.map((text, index) => (
              (studentAnswer?.includes(index) || isTextBlanksQuestion) && (
                <div key={index}>
                  {displayCardText(text)}
                </div>
              )
            ))
            : displayCardText(textWhenIncorrect)}
        </>
      )}
    </CardPreviewContainer>
  )
}

export default memo(CardPreview)
