import React, { useEffect, useState } from 'react'
import { Modal } from 'reactstrap'
import api from '../../api'
import MultipleValueTextInput from '../MultipleValueTextInput/MultipleValueTextInput'
import { validateEmail } from '../../utilities/userUtils'
import { isValidUrl } from '../../utilities/validationUtils'
import { tooltipTexts, getTooltipText } from './utils/tooltipUtils'
import {
  relationshipTypes,
  accessTypes,
  institutions,
  returnTos,
  signingPartnerNameOptions,
  refunds,
  partnerTypeOptions
} from '../../Constants/relationships'
import {
  Container,
  HeaderContainer,
  Header,
  Separator,
  DatePickerWrapper,
  CustomCheckbox
} from '../Students/style'
import {
  ConfirmationModal,
  ModalText,
  ModalButtons,
  ModalOKButton,
  ModalContinueButton,
  Label,
  StandAloneLabel,
  Input,
  Select,
  AsyncSelectComponent,
  CourseAccessSection,
  componentStyles,
  DatePicker,
  CurrencyInput,
  Button
} from './styles'
import { ReactComponent as RemoveIcon } from '../../assets/icons/modal-close.svg'
import { COURSE_ACCESS_TYPES } from './constants'

function EditRelationships () {
  const { expiryDate, returnTo, refund } = tooltipTexts

  const [isLoading, setLoading] = useState(true)
  const [relationships, setRelationships] = useState([])
  const [selectedRelationship, setSelectedRelationship] = useState(null)
  const [displayName, setDisplayName] = useState('')
  const [relationshipTypeOption, setRelationshipTypeOption] = useState(null)
  const [accessTypeOption, setAccessTypeOption] = useState(null)
  const [relationshipStartDate, setRelationshipStartDate] = useState(null)
  const [relationshipAdminEmail, setRelationshipAdminEmail] = useState(null)
  const [relationshipOtherEmails, setRelationshipOtherEmails] = useState([])
  const [partnerDocumentsLink, setPartnerDocumentsLink] = useState(null)
  const [creditGrantingInstitutionOption, setCreditGrantingInstitutionOption] = useState(null)
  const [tokenExpirationDate, setTokenExpirationDate] = useState(null)
  const [returnToOption, setReturnToOption] = useState(null)
  const [refundOption, setRefundOption] = useState(null)
  const [purchasedSeats, setPurchasedSeats] = useState(0)
  const [tokenPrice, setTokenPrice] = useState('')
  const [districtNameOptions, setDistrictNameOptions] = useState([])
  const [districtName, setDistrictName] = useState(null)
  const [signingPartnerName, setSigningPartnerName] = useState(null)
  const [enrollmentOptions, setEnrollmentOptions] = useState([])
  const [partnerType, setPartnerType] = useState(null)
  const [liveProctoring, setLiveProctoring] = useState(true)
  const [hideTOS, setHideTOS] = useState(false)
  const [testRelationship, setTestRelationship] = useState(false)
  const [isUpdating, setUpdating] = useState(false)
  const [successModal, setSuccessModal] = useState(false)

  const isDisabled = isLoading ||
    !selectedRelationship ||
    !displayName ||
    !relationshipTypeOption ||
    !accessTypeOption ||
    !relationshipStartDate ||
    !relationshipAdminEmail ||
    !returnToOption ||
    !enrollmentOptions.length

  const getDistrictNameOptions = async () => {
    const data = await api.getDataTableMetadata({ tableName: 'relationships' })
    const districtNames =
      data.find(({ camelCase }) => camelCase === 'districtName')
    const options = districtNames?.options.map((value) => ({ value, label: value }))
    setDistrictNameOptions(options)
    return options
  }

  const submit = async () => {
    const body = {
      relationshipName: selectedRelationship?.relationshipName,
      displayName,
      relationshipType: relationshipTypeOption
        ? [relationshipTypeOption.value]
        : [],
      accessType: accessTypeOption?.value,
      relationshipStartDate,
      relationshipAdminEmail,
      relationshipOtherEmails: relationshipOtherEmails.join('\n'),
      partnerDocumentsLink,
      creditGrantingInstitution: creditGrantingInstitutionOption?.value,
      tokenExpirationDate,
      returnTo: returnToOption?.value,
      refundability: refundOption?.value,
      purchasedSeats: parseInt(purchasedSeats),
      tokenPrice: tokenPrice?.length ? Number(tokenPrice) : null,
      liveProctoring,
      hideTOS,
      test: testRelationship,
      ...(districtName ? { districtName: districtName.value } : {}),
      ...(signingPartnerName ? { signingPartnerName: signingPartnerName.value } : {}),
      ...(partnerType ? { partnerType: partnerType.value } : {}),
      ...(enrollmentOptions.length ? { enrollmentOptions } : {})
    }

    setUpdating(true)
    const response = await api.updateRelationship(body)
    setUpdating(false)
    const { success } = response || {}
    if (success) {
      setSuccessModal(true)
    }
  }

  function getOptions (optionsObj) {
    return Object.values(optionsObj)
  }

  useEffect(() => {
    const getRelationships = async () => {
      setLoading(true)
      const relationships = await api.getStudentRelationships('', false)
      const relationShipOptions = relationships.map(
        relationship => {
          return {
            ...relationship,
            value: relationship.id,
            label: relationship.relationshipName
          }
        }
      )
      setRelationships(relationShipOptions)
      setLoading(false)
    }
    if (isUpdating) return
    getRelationships()
  }, [isUpdating])

  useEffect(() => {
    const {
      displayName,
      relationshipType: [relationshipTypeValue],
      accessType,
      relationshipStartDate,
      relationshipAdminEmail,
      relationshipOtherEmails,
      partnerDocumentsLink,
      creditGrantingInstitution,
      tokenExpirationDate,
      returnTo,
      refundability,
      purchasedSeats,
      districtName,
      signingPartnerName,
      tokenPrice,
      liveProctoring,
      hideTOS,
      partnerType,
      test,
      enrollmentOptions
    } = selectedRelationship || { relationshipType: [] }
    setDisplayName(displayName || '')
    setRelationshipTypeOption(
      getOptions(relationshipTypes)
        .find(item => item.value === relationshipTypeValue)
    )
    setAccessTypeOption(
      getOptions(accessTypes).find(item => item.value === accessType)
    )
    setRelationshipStartDate(relationshipStartDate)
    setRelationshipAdminEmail(relationshipAdminEmail || '')
    setRelationshipOtherEmails(relationshipOtherEmails?.split('\n') || [])
    setPartnerDocumentsLink(partnerDocumentsLink || '')
    setCreditGrantingInstitutionOption(
      getOptions(institutions).find(item => item.value === creditGrantingInstitution)
    )
    setTokenExpirationDate(tokenExpirationDate)
    setReturnToOption(
      getOptions(returnTos).find(item => item.value === returnTo)
    )
    setRefundOption(
      getOptions(refunds).find(item => item.value === refundability)
    )
    setPurchasedSeats(purchasedSeats || 0)
    setTokenPrice(tokenPrice || '')
    setLiveProctoring(liveProctoring)
    setHideTOS(hideTOS)
    setTestRelationship(test)
    setDistrictName(districtNameOptions.find(option => option.value === districtName))
    setSigningPartnerName(signingPartnerNameOptions.find(option => option.value === signingPartnerName))
    setPartnerType(partnerTypeOptions.find(option => option.value === partnerType))
    setEnrollmentOptions(enrollmentOptions || [])
  }, [selectedRelationship, districtNameOptions])

  return (
    <Container>
      <Modal isOpen={successModal} centered>
        <ConfirmationModal>
          <ModalText>
            <div>
              `{selectedRelationship?.relationshipName}` has successfully been updated.
            </div>
            <div>
              Click OK to return to Manage Relationships page.
            </div>
          </ModalText>
          <ModalButtons>
            <a href='#/relationships'>
              <ModalOKButton
                data-testid='ok-button'
              >
                OK
              </ModalOKButton>
            </a>
            <ModalContinueButton
              data-testid='ok-button'
              onClick={() => {
                setRelationships([])
                setSelectedRelationship(null)
                setSuccessModal(false)
              }}
            >
              KEEP EDITING
            </ModalContinueButton>
          </ModalButtons>
        </ConfirmationModal>
      </Modal>
      <HeaderContainer>
        <Header>edit relationship</Header>
      </HeaderContainer>
      <Separator />
      <StandAloneLabel>Relationship Name</StandAloneLabel>
      <Select
        isLoading={isLoading}
        styles={componentStyles}
        placeholder=''
        value={selectedRelationship}
        options={relationships}
        onChange={relationship => setSelectedRelationship(relationship)}
      />
      <Label htmlFor='displayName'>
        Display Name
        <Input
          id='displayName'
          type='text'
          value={displayName}
          onChange={(e) => setDisplayName(e.target.value)}
          disabled={isLoading}
        />
      </Label>
      <Label htmlFor='relationshipType'>
        Relationship Type
      </Label>
      <Select
        styles={componentStyles}
        placeholder=''
        value={relationshipTypeOption}
        options={!isLoading && getOptions(relationshipTypes)}
        onChange={(e) => setRelationshipTypeOption(e)}
      />
      <Label htmlFor='accessType'>
        Access Type
      </Label>
      <Select
        styles={componentStyles}
        placeholder=''
        value={accessTypeOption}
        options={!isLoading && getOptions(accessTypes)}
        onChange={(e) => setAccessTypeOption(e)}
      />
      <Label htmlFor='relationshipStartDate'>
        Start Date
      </Label>
      <DatePickerWrapper>
        <DatePicker
          placeholderText='MM/DD/YYYY'
          selected={relationshipStartDate && new Date(relationshipStartDate)}
          onChange={(date) =>
            setRelationshipStartDate(date?.toISOString().slice(0, 10))
          }
          disabled={isLoading}
        />
      </DatePickerWrapper>
      <Label htmlFor='relationshipAdminEmail'>
        Admin Email
        <Input
          id='relationshipAdminEmail'
          invalid={relationshipAdminEmail && !validateEmail(relationshipAdminEmail)}
          value={relationshipAdminEmail}
          type='text'
          onChange={(e) => setRelationshipAdminEmail(e.target.value)}
          disabled={isLoading}
        />
      </Label>
      <Label htmlFor='relationshipOtherEmails'>
        Other Partner Emails
        <MultipleValueTextInput
          values={relationshipOtherEmails}
          onItemAdded={(item, allItems) =>
            setRelationshipOtherEmails(allItems)
          }
          onItemDeleted={(item, allItems) =>
            setRelationshipOtherEmails(allItems)}
          name='relationshipOtherEmails'
          deleteButton={<RemoveIcon />}
          disabled={isLoading}
        />
      </Label>
      <Label htmlFor='partnerDocumentsLink'>
        Partner Documents Folder
        <Input
          id='partnerDocumentsLink'
          invalid={partnerDocumentsLink && !isValidUrl(partnerDocumentsLink)}
          value={partnerDocumentsLink}
          type='text'
          onChange={(e) => setPartnerDocumentsLink(e.target.value)}
          disabled={isLoading}
        />
      </Label>
      <Label>
        Credit Granting Institution
      </Label>
      <Select
        styles={componentStyles}
        placeholder=''
        options={!isLoading && getOptions(institutions)}
        value={creditGrantingInstitutionOption}
        onChange={(e) => setCreditGrantingInstitutionOption(e)}
      />
      <StandAloneLabel>
        Token Expiration Date{' '}
        <span data-tip={getTooltipText(expiryDate.text1, expiryDate.text2)} />
      </StandAloneLabel>
      <DatePickerWrapper>
        <DatePicker
          placeholderText='MM/DD/YYYY'
          selected={tokenExpirationDate && new Date(tokenExpirationDate)}
          onChange={(date) =>
            setTokenExpirationDate(date?.toISOString().slice(0, 10))
          }
          disabled={isLoading}
        />
      </DatePickerWrapper>
      <StandAloneLabel>
        Return To
        <span data-tip={getTooltipText(returnTo.text1, returnTo.text2)} />
      </StandAloneLabel>
      <Select
        styles={componentStyles}
        placeholder=''
        options={!isLoading && getOptions(returnTos)}
        value={returnToOption}
        onChange={(e) => setReturnToOption(e)}
      />
      <StandAloneLabel>
        Refunds
        <span data-tip={getTooltipText(refund.text1, refund.text2)} />
      </StandAloneLabel>
      <Select
        styles={componentStyles}
        placeholder=''
        options={!isLoading && getOptions(refunds)}
        value={refundOption}
        onChange={(e) => setRefundOption(e)}
      />
      <Label htmlFor='purchasedSeats'>
        Purchased Seats
        <Input
          id='purchasedSeats'
          value={purchasedSeats}
          type='number'
          onChange={(e) => setPurchasedSeats(e.target.value)}
          disabled={isLoading}
        />
      </Label>
      <Label htmlFor='tokenPrice'>
        Token Purchase Price
        <CurrencyInput
          id='tokenPrice'
          value={tokenPrice}
          prefix='$'
          onValueChange={value => setTokenPrice(value)}
          disabled={isLoading}
          reduced
        />
      </Label>
      <Label>
        District Name
      </Label>
      <AsyncSelectComponent
        styles={componentStyles}
        defaultOptions
        cacheOptions
        placeholder=''
        loadOptions={getDistrictNameOptions}
        value={districtName}
        onChange={(e) => setDistrictName(e)}
      />
      <Label>
        Signing PartnerName
      </Label>
      <Select
        styles={componentStyles}
        placeholder=''
        options={isLoading ? [] : signingPartnerNameOptions}
        value={signingPartnerName}
        onChange={(e) => setSigningPartnerName(e)}
      />
      <Label>
        Partner Type
      </Label>
      <Select
        styles={componentStyles}
        placeholder=''
        options={isLoading ? [] : partnerTypeOptions}
        value={partnerType}
        onChange={(partnerType) => setPartnerType(partnerType)}
      />
      <CourseAccessSection>
        Course Access
        {COURSE_ACCESS_TYPES.map(({ value, label, id }) => (
          <CustomCheckbox
            mb='0'
            key={value}
          >
            {label}
            <input
              type='checkbox'
              data-testid={value}
              id={id}
              name={label}
              checked={enrollmentOptions.includes(value) || false}
              onChange={() =>
                setEnrollmentOptions((prevState) => {
                  if (prevState.includes(value)) {
                    return prevState.filter((item) => item !== value)
                  }
                  return [...prevState, value]
                })
              }
            />
            <span />
          </CustomCheckbox>
        ))}
      </CourseAccessSection>
      <CourseAccessSection>
        Others
        <CustomCheckbox
          mb='0'
        >
          In-person proctoring
          <input
            type='checkbox'
            data-testid='In-person-proctoring'
            id='In-person-proctoring'
            name='In-person-proctoring'
            checked={!!liveProctoring}
            onChange={() => setLiveProctoring(!liveProctoring)}
          />
          <span />
        </CustomCheckbox>
        <CustomCheckbox
          mb='0'
        >
          Hide TOS
          <input
            type='checkbox'
            data-testid='Hide-TOS'
            id='Hide-TOS'
            name='Hide-TOS'
            checked={!!hideTOS}
            onChange={() => setHideTOS(!hideTOS)}
            disabled={isLoading}
          />
          <span />
        </CustomCheckbox>
        <CustomCheckbox
          mb='0'
        >
          Test Relationship
          <input
            type='checkbox'
            data-testid='test-relationship'
            id='test-relationship'
            name='test-relationship'
            checked={!!testRelationship}
            onChange={() => setTestRelationship(!testRelationship)}
            disabled={isLoading}
          />
          <span />
        </CustomCheckbox>
      </CourseAccessSection>
      <Button
        data-testid='edit-relationship'
        className='btn-custom btn-primary'
        disabled={isDisabled || isUpdating}
        onClick={submit}
      >
        edit relationship
      </Button>
    </Container>
  )
}

EditRelationships.displayName = 'EditRelationships'
export default EditRelationships
