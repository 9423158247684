import { EXAM_1, EXAM_2, EXAM_3, EXAM_4, FINAL_EXAM, FINAL_EXAM_I, FINAL_EXAM_II, MIDTERM_EXAM_1, MIDTERM_EXAM_2 } from './examTypes'

export const RETAKE_WINDOW_START = 'retake window start'
export const RETAKE_WINDOW_END = 'retake window end'
export const RETAKE_REASON = 'retake reason'
export const PARTNER_REQUEST = 'partner request'

export const EXAM_KEYS = {
  // standard cohorts
  [MIDTERM_EXAM_1]: { start: 'midterm1RetakeStart', end: 'midterm1Deadline' },
  [MIDTERM_EXAM_2]: { start: 'midterm2RetakeStart', end: 'midterm2Deadline' },
  [FINAL_EXAM]: { start: 'finalRetakeStart', end: 'finalDeadline' },

  // 39 week cohorts
  [EXAM_1]: { start: 'midterm1RetakeStart', end: 'midterm1Deadline' },
  [EXAM_2]: { start: 'midterm2RetakeStart', end: 'midterm2Deadline' },
  [EXAM_3]: { start: 'exam3RetakeStart', end: 'exam3RetakeDeadline' },
  [EXAM_4]: { start: 'exam4RetakeStart', end: 'exam4RetakeDeadline' },
  [FINAL_EXAM_I]: { start: 'exam5RetakeStart', end: 'exam5RetakeDeadline' },
  [FINAL_EXAM_II]: { start: 'finalRetakeStart', end: 'finalDeadline' }
}

export const COURSE_NAMES_WITH_RETAKES_AVAILABLE = [
  'Calculus I',
  'Calculus I - EXT',
  'College Algebra',
  'College Algebra - EXT',
  'Computer Science I',
  'Computer Science I - EXT',
  'Introduction to Business',
  'Introduction to Business - EXT',
  'Introduction to Financial Accounting',
  'Introduction to Financial Accounting - EXT',
  'Introduction to Philosophy',
  'Introduction to Psychology',
  'Introduction to Psychology - EXT',
  'Introduction to Psychology - GGU',
  'Introduction to Sociology',
  'Introduction to Sociology - EXT',
  'Introduction to Statistics',
  'Introduction to Statistics - EXT',
  'Precalculus',
  'Precalculus - EXT',
  'Principles of Economics',
  'Principles of Economics - EXT'
]
