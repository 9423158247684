import React, { useEffect, useState } from 'react'
import api from '../../api'
import {
  SubmissionFrame,
  SubmissionHeader,
  SubmissionName,
  LinksContainer,
  DownloadLink,
  AnnotateSubmissionButton,
  BoxArrowIcon
} from './style'
import DownloadIcon from '../../assets/icons/download-link.svg'
import useFetchVideo from '../VideoPlayer/useFetchVideo'

const SubmissionsWrapper = ({
  children,
  content: { url, type, fileName, name, streamUrl },
  kamiUploadStatus,
  handleAnnotateSubmission,
  isCohortEndFourYearsAgo
}) => {
  const [isDownloading, setIsDownloading] = useState(false)
  const isMp4 = type?.[0] === '.mp4'
  const { isUrlLoading, isFileNotFound } =
    useFetchVideo(isMp4 ? streamUrl : null)

  const [showDownloadButton, setShowDownloadButton] = useState(true)

  useEffect(() => {
    const shouldHideDownloadButton = isFileNotFound &&
      isCohortEndFourYearsAgo

    if (shouldHideDownloadButton) {
      setShowDownloadButton(false)
    } else {
      setShowDownloadButton(true)
    }
  }, [type, isFileNotFound, isCohortEndFourYearsAgo, isMp4])

  const downloadAssignment = () => {
    const download = (url) => {
      const link = document.createElement('a')
      link.href = url
      link.download = fileName ? fileName + type?.[0] : name
      link.click()
    }

    if (streamUrl) {
      setIsDownloading(true)
      api.getToken()
        .then(token => {
          const options = {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
          return fetch(streamUrl, options)
        })
        .then(response => response.blob())
        .then(blob => {
          const url = URL.createObjectURL(blob)
          download(url)
          setIsDownloading(false)
        })
        .catch(err => {
          console.error(err)
          setIsDownloading(false)
        })
      return
    }
    download(url)
  }

  return (
    <SubmissionFrame>
      <SubmissionHeader>
        <SubmissionName>{fileName || name}</SubmissionName>
        <LinksContainer>
          {!streamUrl && (
            <AnnotateSubmissionButton
              disabled={kamiUploadStatus.isLoading}
              error={kamiUploadStatus.isError}
              onClick={handleAnnotateSubmission}
              data-testid='annotate-submission'
            >
              <BoxArrowIcon />
              <p>annotate file</p>
            </AnnotateSubmissionButton>
          )}
          {showDownloadButton && !isUrlLoading && (
            <DownloadLink
              onClick={downloadAssignment}
              disabled={isDownloading}
              data-testid='download-file'
            >
              <img src={DownloadIcon} alt='Download' />
              {isDownloading ? <i className='fa fa-spinner fa-spin' /> : 'Download'}
            </DownloadLink>
          )}
        </LinksContainer>
      </SubmissionHeader>
      {children}
    </SubmissionFrame>
  )
}

SubmissionsWrapper.displayName = 'SubmissionsWrapper'

export default SubmissionsWrapper
