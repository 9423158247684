import React from 'react'
import Select from 'react-select'
import AsyncSelect from 'react-select/async'
import { SelectWrapper, dropDownStyles } from './styles'
import { Label } from 'reactstrap'
import { FilterInput } from '../styles'
import { getColumnMetaData, getOperators } from '../utils'
import api from '../../../api'
import { DatePicker } from '../../AdminStudentDrop/styled'

const booleanOptions = [
  {
    label: 'True',
    value: 'true'
  },
  {
    label: 'False',
    value: 'false'
  }
]

function FilterItem ({ option, setOption, metaData, tableHeaders }) {
  const {
    filterColumn = '',
    filterOperator = '',
    filterValue = ''
  } = option || {}

  const { value: columnValue } = filterColumn || {}

  const { dataType, options, isJoined, joinedTo } = getColumnMetaData(
    columnValue,
    metaData
  )

  const operators = getOperators(columnValue, metaData)

  const textOptions =
    options?.map((option) => {
      return { label: option, value: option }
    }) || []

  const getJoinedToOptions = async ({ tableName, inputValue }) => {
    const result = await api.getTableIdValues({ tableName })
    const filteredResult = result.filter(({ value }) => {
      return value?.toLowerCase().includes(inputValue.toLowerCase())
    })
    const fields = filteredResult.slice(0, 100)
    return fields.map((row) => {
      return {
        ...row,
        label: row.value
      }
    })
  }

  const getValueInput = (dataType) => {
    const { filterOperator: { label } = {} } = option || {}
    const isContainsOperator = label === 'contains'

    switch (true) {
      case dataType === 'boolean':
        return (
          <Select
            options={booleanOptions}
            onChange={({ value }) =>
              setOption({ ...option, filterValue: value })
            }
            placeholder='Select value'
            value={booleanOptions.find((item) => item.value === filterValue)}
            styles={dropDownStyles}
            width='300px'
            height='56px'
            menuPortalTarget={document.body}
            data-testid='boolean-select'
          />
        )
      case dataType === 'text' && options?.length > 0:
        return (
          <Select
            options={textOptions}
            onChange={({ value }) =>
              setOption({ ...option, filterValue: value })
            }
            placeholder='Select value'
            value={textOptions.find((item) => item.value === filterValue)}
            styles={dropDownStyles}
            width='300px'
            height='56px'
            menuPortalTarget={document.body}
          />
        )
      case ['date', 'timestamp without time zone'].includes(dataType):
        return (
          <DatePicker
            isClearable
            showMonthDropdown
            showYearDropdown
            dropdownMode='select'
            dateFormat='yyyy-MM-dd'
            placeholderText='Select date'
            data-testid='date-picker'
            selected={filterValue ? new Date(filterValue) : null}
            onChange={(date) => {
              setOption({
                ...option,
                filterValue: date?.toISOString().split('T')[0] || ''
              })
            }}
          />
        )
      case isContainsOperator:
        return (
          <FilterInput
            style={{ width: '300px', marginTop: 0 }}
            type='text'
            placeholder='Enter value'
            value={filterValue}
            onChange={(ev) =>
              setOption({ ...option, filterValue: ev.target.value })
            }
          />
        )
      case isJoined:
        return (
          <AsyncSelect
            key={JSON.stringify(filterColumn)}
            placeholder='Select value'
            className='select'
            defaultOptions
            cacheOptions
            loadOptions={async (inputValue) => {
              return getJoinedToOptions({ tableName: joinedTo, inputValue })
            }}
            onChange={({ value }) =>
              setOption({ ...option, filterValue: value })
            }
            value={{ value: filterValue, label: filterValue }}
            styles={dropDownStyles}
            width='300px'
            height='56px'
            menuPortalTarget={document.body}
            data-testid='joined-select'
          />
        )
      default:
        return (
          <FilterInput
            style={{ width: '300px', marginTop: 0 }}
            type='text'
            placeholder='Enter value'
            value={filterValue}
            onChange={(ev) =>
              setOption({ ...option, filterValue: ev.target.value })
            }
          />
        )
    }
  }

  return (
    <>
      <SelectWrapper>
        <Label>Column</Label>
        <Select
          options={tableHeaders}
          onChange={(filterColumn) => setOption({ filterColumn })}
          placeholder='Select column'
          value={filterColumn}
          styles={dropDownStyles}
          width='200px'
          height='56px'
          menuPortalTarget={document.body}
        />
      </SelectWrapper>
      <SelectWrapper>
        <Label>Operation</Label>
        <Select
          options={operators}
          onChange={(filterOperator) => {
            setOption({ ...option, filterOperator })
          }}
          placeholder='Select operator'
          value={filterOperator}
          styles={dropDownStyles}
          width='160px'
          height='56px'
          menuPortalTarget={document.body}
        />
      </SelectWrapper>
      <SelectWrapper>
        <Label>Value</Label>
        {getValueInput(dataType)}
      </SelectWrapper>
    </>
  )
}

FilterItem.displayName = 'FilterItem'
export default FilterItem
