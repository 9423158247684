import React from 'react'
import Select from 'react-select'
import AsyncSelect from 'react-select/async'
import api from '../../api'
import {
  CustomDatePicker, dropDownStyles
} from '../global.styled'
import { DateContainer } from '../Cohorts/styled'
import { FormInput } from './styles'
import { getDateString } from '../../utilities/dateTimeUtil'

function RecordInput (props) {
  const { field, row, setRow, canUserEditDatabase } = props
  const {
    camelCase,
    columnName,
    dataType,
    options,
    isLookup,
    isJoined,
    joinedTo,
    isAutoGenerated
  } = field
  const dataValue = camelCase && row[camelCase]

  const getJoinedToOptions = async ({ tableName, inputValue }) => {
    const result = await api.getTableIdValues({ tableName })
    const filteredResult = result.filter(({ value }) => {
      return value?.toString().toLowerCase().includes(inputValue.toLowerCase())
    })
    const fields = filteredResult.slice(0, 100)
    return fields.map(row => {
      return {
        ...row,
        label: row.value
      }
    })
  }

  switch (dataType) {
    case 'text':
      if (options) {
        const textOptions = options?.map(option => {
          return { label: option, value: option }
        }) || []
        return <Select
          className='select'
          options={textOptions}
          onChange={({ value }) => camelCase && setRow({ ...row, [camelCase]: value })}
          placeholder='Select Value'
          value={textOptions.find(item => item.value === dataValue)}
          styles={dropDownStyles}
          width='100%'
          height='56px'
          isDisabled={!canUserEditDatabase}
        />
      }
      return <FormInput
        key={columnName}
        type='text'
        placeholder={canUserEditDatabase ? 'Start typing' : ''}
        onChange={(e) => {
          camelCase && setRow({ ...row, [camelCase]: e.target.value })
        }}
        value={dataValue || ''}
        disabled={!canUserEditDatabase}
      />
    case 'numeric':
      return <FormInput
        key={columnName}
        type='number'
        placeholder={canUserEditDatabase ? 'Start typing' : ''}
        onChange={(e) => {
          camelCase && setRow({ ...row, [camelCase]: Number(e.target.value) })
        }}
        value={dataValue || ''}
        disabled={!canUserEditDatabase}
      />
    case 'boolean':
      const booleanOptions = [
        { label: 'True', value: true },
        { label: 'False', value: false }
      ]
      return <Select
        key={columnName}
        className='select'
        placeholder={''}
        value={booleanOptions.find(option => option.value === !!dataValue)}
        styles={dropDownStyles}
        width='100%'
        height='56px'
        options={booleanOptions}
        onChange={(option) => {
          camelCase && setRow({ ...row, [camelCase]: option.value })
        }}
        isSearchable={false}
        isDisabled={!canUserEditDatabase}
      />
    case 'date':
      const dateValue = dataValue ? new Date(dataValue) : null
      return (
        <DateContainer
          key={columnName}
        >
          <CustomDatePicker
            data-testid='date-field'
            placeholderText='MM/DD/YYYY'
            dateFormat='MM/dd/yyyy'
            selected={dateValue}
            openToDate={dateValue || new Date()}
            disabled={[
              'applicationinfo',
              'cohortupdate',
              'created_time',
              'createdon',
              'createdat',
              'datedobchanged',
              'updatedat'
            ].includes(columnName) || !canUserEditDatabase}
            onChange={(date) => {
              camelCase && setRow({ ...row, [camelCase]: getDateString(date) || null })
            }}
            isClearable
          />
        </DateContainer>
      )
    case 'timestamp without time zone':
      const timestamp = dataValue ? new Date(dataValue) : null
      return (
        <DateContainer
          key={columnName}
        >
          <CustomDatePicker
            data-testid='date-field'
            placeholderText='MM/DD/YYYY --:--'
            dateFormat='MM/dd/yyyy, h:mm aa'
            selected={timestamp}
            openToDate={timestamp || new Date()}
            disabled={[
              'applicationinfo',
              'cohortupdate',
              'created_time',
              'createdon',
              'createdat',
              'datedobchanged',
              'updatedat'
            ].includes(columnName) || !canUserEditDatabase}
            onChange={(date) => {
              camelCase && setRow({ ...row, [camelCase]: date?.toISOString() ?? null })
            }}
            showTimeSelect
            timeFormat='h:mm aa'
            timeCaption='time'
            isClearable
          />
        </DateContainer>
      )
    case 'ARRAY':
      const dataArray = dataValue || []
      let selections = []
      if (isJoined) {
        selections = dataArray.map(value => {
          return { ...value, label: value?.value }
        }) || []
        return (
          <AsyncSelect
            key={columnName}
            className='select'
            defaultOptions
            cacheOptions
            value={selections}
            loadOptions={async (inputValue) => {
              return getJoinedToOptions({ tableName: joinedTo, inputValue })
            }}
            onChange={(options) => {
              setRow({ ...row, [camelCase]: options })
            }}
            styles={dropDownStyles}
            width='100%'
            height='56px'
            isMulti
            isScrollable
            isDisabled={!canUserEditDatabase}
          />
        )
      }
      if (isLookup || options?.length > 0) {
        selections = dataArray.map(value => {
          return { value, label: value }
        }) || []
      }
      return (
        <Select
          key={columnName}
          className='select'
          placeholder={''}
          isDisabled={isLookup || isAutoGenerated || !canUserEditDatabase}
          options={
            options?.map((option) => {
              return { label: option, value: option }
            }) || []
          }
          onChange={(options) => {
            setRow({
              ...row,
              [camelCase]: options?.map((option) => option.value) || []
            })
          }}
          value={selections}
          styles={dropDownStyles}
          width='100%'
          height='56px'
          isMulti
        />
      )
    default:
      return null
  }
}

export default RecordInput
