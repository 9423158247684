import React, { useState, useEffect } from 'react'
import { FormGroup, Label, Input, Button } from 'reactstrap'
import Select from 'react-select'
import cuid from 'cuid'
import { validateEmail } from '../../utilities/userUtils'
import api from '../../api'
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal'
import AlertModal from '../AlertModal/AlertModal'
import {
  CustomDatePicker,
  DatePickerContainer,
  FormWrapper,
  VipCheckBox,
  selectInputStyles
} from './styled'
import { useDebouncedEffect } from '../../Hooks/useDebounce'
import { getAdditionalCourseWithAtId, getValidCourses, mergeCohortsOfDuplicateCourses } from '../../utilities/courseUtils'
import { additionalCourseIdToName } from '../../config'
import WarningAlert from './WarningAlert'
import { getActiveCohortSortedVipFirst } from '../../utilities/cohortUtils'
import { CREDIT_GRANTING_INSTITUTIONS } from '../../Constants/vipTool'
const IS_TEST = process.env.NODE_ENV === 'test'

const initialState = {
  id: '',
  name: '',
  email: '',
  creditGrantingInstitution: null,
  courses: [],
  cohorts: [],
  date: '',
  vipGradedContent: false,
  sendEmail: false,
  contractorEmployee: false,
  notes: ''
}

const initialStudentData = {
  existsInRegistration: false,
  id: null,
  firstName: null,
  lastName: null,
  name: '',
  preferredName: null,
  dateToRemoveAccess: null,
  dateOfBirth: null,
  courses: [],
  cohorts: [],
  notes: '',
  dateTOS: null,
  hasOLRF: false,
  hasAIF: false,
  notStudent: false,
  vipGradedContent: false,
  under13: false,
  grantedException: false,
  vipEmailSent: false,
  contractorEmployee: false
}

function VipUser () {
  const [formState, setFormState] = useState(initialState)
  const [allCourses, setCourses] = useState([])
  const [totalCourses, setTotalCourses] = useState([])
  const [allCohorts, setAllCohorts] = useState([])
  const [courseCohorts, setCourseCohorts] = useState([])
  const [emailError, setEmailError] = useState(false)
  const [nameError, setNameError] = useState(false)
  const [coursesError, setCoursesError] = useState(false)
  const [cohortsError, setCohortsError] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [alertModal, setAlertModal] = useState(false)
  const [messageOnRequest, setMessageOnRequest] = useState('')
  const [loading, setLoading] = useState(false)
  const [isDisabled, setIsDisabled] = useState(true)
  const [isEditVipUser, setIsEditVipUser] = useState(false)
  const [isFetchingData, setIsFetchingData] = useState(true)
  const [oldVipStudentData, setOldVipStudentData] = useState(initialStudentData)
  const [warning, setWarning] = useState('')
  const isUpgradingToVip = oldVipStudentData?.vip === false

  useEffect(() => {
    Promise.all([fetchCoursesAndCohorts(), generateUid()])
      .then(() =>
        setIsFetchingData(false)
      )
    // eslint-disable-next-line
  }, [])

  const fetchCoursesAndCohorts = async () => {
    let totalCourses = await api.getCourses()
    totalCourses = getValidCourses(totalCourses)
    const filteredCourses = mergeCohortsOfDuplicateCourses(totalCourses)
    setCourses(
      filteredCourses.map(course => ({
        ...course,
        value: course.at_id,
        label: course.name
      }))
    )

    setTotalCourses(totalCourses)
    const totalCohorts = totalCourses
      .flatMap(course => course.cohorts)
      .map(({ name, id } = {}) => ({ label: name, value: id }))

    setAllCohorts(totalCohorts)
  }

  useDebouncedEffect(() => fetchStudentDetails(), [formState.email], 1000)

  const fetchStudentDetails = async () => {
    const { email } = formState
    setFormState({ ...initialState, email })
    setOldVipStudentData(initialStudentData)
    if (!email) {
      setIsDisabled(true)
      return
    }
    const isEmailValidated = validateEmail(email)
    if (!isEmailValidated) return setEmailError('Invalid email')

    setIsFetchingData(true)
    const studentData = await api.getStudentData(email)
    if (!studentData) return setIsFetchingData(false)

    const { cohorts, courses, dateToRemoveAccess } = studentData
    // when student does not exist in AT, id is absent
    if (!studentData.id) {
      setIsEditVipUser(false)
      setIsDisabled(false)
      setIsFetchingData(false)
      setFormState({ ...initialState, email, creditGrantingInstitution: CREDIT_GRANTING_INSTITUTIONS[0] })
      return
    }
    setOldVipStudentData(studentData)

    if (!studentData.vip) {
      setWarning(`This user has a student account. Submitting this form will update 
      the user's status from student to VIP for all cohorts in which they're enrolled.`)
    }

    const keysWithDifferentName = ['cohorts', 'courses', 'date']
    const studentCourses = allCourses.filter(course =>
      courses && courses.length && courses.includes(course.value)
    )
    const studentCohorts = allCohorts.filter(cohort =>
      cohorts && cohorts.length && cohorts.includes(cohort.value)
    )
    onChange('courses', studentCourses)
    onChange('cohorts', studentCohorts)
    if (dateToRemoveAccess) onChange('date', dateToRemoveAccess)

    if (!studentData.name) {
      const firstName = studentData?.firstName || ''
      const lastName = studentData?.lastName || ''
      studentData.name = `${firstName} ${lastName}`.trim()
    }

    Object.keys(initialState).forEach(key => {
      if (studentData[key] && !keysWithDifferentName.includes(key)) {
        onChange(key, studentData[key])
      }
    })

    setIsFetchingData(false)
    setIsEditVipUser(true)
    setIsDisabled(false)
  }

  const generateUid = () => {
    const id = IS_TEST ? 'scklfkjn9a0002sgsdc77ve9aj' : cuid()
    onChange('id', id)
  }

  const onChange = (name, value) => {
    if (name === 'email') {
      validateEmail(value) && setEmailError(false)
      setIsFetchingData(false)
      if (warning) setWarning('')
    }
    if (name === 'name') {
      value && setNameError(false)
    }
    if (name === 'courses') {
      value && setCoursesError(false)
    }
    if (name === 'cohorts') {
      value && setCohortsError(false)
    }

    setFormState(prevState => ({ ...prevState, [name]: value }))
  }

  const { id, name, email, creditGrantingInstitution, courses = [], cohorts, date, notes, sendEmail,
    contractorEmployee, vipGradedContent } = formState

  useEffect(() => {
    if (!courses || !courses.length) {
      return setFormState(prevState => ({ ...prevState, cohorts: [] }))
    }

    const courseCohorts = courses.map(course => course.cohorts).flat()
    const orderedCohorts = getActiveCohortSortedVipFirst(courseCohorts)
    setCourseCohorts(
      orderedCohorts.map(({ name, id } = {}) => ({ label: name, value: id }))
    )

    setFormState(prevState => ({ ...prevState, cohorts }))
    // eslint-disable-next-line
  }, [courses])

  const submit = () => {
    if (!name) setNameError('Please fill the name')
    const courseExists = courses && courses.length
    if (!courseExists) setCoursesError('Please select courses')
    const cohortExists = cohorts && cohorts.length
    if (!cohortExists) setCohortsError('Please select required cohorts')
    if (!email) return setEmailError('Please fill the email')
    const isEmailValidated = validateEmail(email)
    if (!isEmailValidated) setEmailError('Invalid email')
    if (name && isEmailValidated && courseExists &&
      cohortExists) setShowModal(true)
  }

  const onConfirmation = async () => {
    setShowModal(false)
    setLoading(true)
    const { addNewVipStudent, updateVipStudent } = api

    const additionalIdExists = courses.findIndex(
      (course) => additionalCourseIdToName(course.id)?.length > 1
    )
    if (additionalIdExists > -1) {
      courses[additionalIdExists].value = getAdditionalCourseWithAtId(
        courses[additionalIdExists].value,
        totalCourses
      )?.at_id || courses[additionalIdExists].value
    }
    const vipStudent = {
      id,
      name,
      email,
      courses: courses.map(courses => courses.value),
      dateToRemoveAccess: date,
      notes,
      cohorts: cohorts.map(cohorts => cohorts.value),
      vipGradedContent,
      contractorEmployee,
      sendEmail,
      vip: true
    }
    if (isEditVipUser) {
      // if we already sent email to student before
      if (oldVipStudentData.vipEmailSent) vipStudent.sendEmail = false
    }
    if (!vipStudent.dateToRemoveAccess) delete vipStudent.dateToRemoveAccess
    const response = isEditVipUser
      ? await updateVipStudent(vipStudent, vipStudent.id)
      : await addNewVipStudent(vipStudent)

    const { success, message } = response
    if (success) {
      const messageOnRequestText = getMessageOnRequestText()
      setMessageOnRequest(messageOnRequestText)
      clearInputFields()
      generateUid()
    } else {
      setMessageOnRequest(message)
    }
    setWarning('')
    setLoading(false)
    setAlertModal(true)
  }

  const getMessageOnRequestText = () => {
    if (isUpgradingToVip) return 'Student is successfully updated to VIP'

    return `Vip user ${isEditVipUser ? 'updated' : 'created'} successfully`
  }

  const getConfirmationModalText = () => {
    if (isUpgradingToVip) return 'Are you sure you want to update this user to VIP access?'

    return isEditVipUser
      ? 'The VIP user details will be updated. Do you want to proceed?'
      : `A new VIP account will be created with this user information.
         Do you want to proceed?`
  }

  const clearInputFields = () => setFormState(initialState)

  return (
    <>
      {warning && <WarningAlert message={warning} />}
      <FormWrapper className='form-wrapper' style={{ margin: '24px 0' }}>
        <FormGroup>
          <Label className='field-label'>Email</Label>
          <div className='text-field email'>
            {emailError && (
              <p data-testid='email-error' className='error'>
                {emailError}
              </p>
            )}
            <Input
              type='email'
              data-testid='email-field'
              value={email}
              placeholder='VIP Email'
              onChange={e => onChange('email', e.target.value)}
              disabled={isFetchingData}
              className={`${emailError ? 'red-border' : ''}`}
            />
            {isFetchingData && (
              <i
                data-testid='email-loading'
                className='fa fa-spinner fa-spin'
              />
            )}

          </div>
          <Label className='field-label'>Name</Label>
          <div className='text-field'>
            {nameError && <p className='error'>{nameError}</p>}
            <Input
              data-testid='name-field'
              type='text'
              value={name}
              placeholder='VIP Full Name'
              onChange={e => onChange('name', e.target.value)}
              disabled={isDisabled}
              className={`${nameError ? 'red-border' : ''}`}
            />
          </div>
          <Label className='field-label'>Credit-granting institution</Label>
          <div className='text-field'>
            <Select
              data-testid='select-input'
              options={CREDIT_GRANTING_INSTITUTIONS}
              value={creditGrantingInstitution}
              onChange={creditGrantingInstitution => {
                onChange('creditGrantingInstitution', creditGrantingInstitution)
              }}
              placeholder='Select institution'
              styles={selectInputStyles}
              isDisabled={isDisabled}
            />
          </div>
          <Label className='field-label'>Courses</Label>
          <div className='text-field'>
            {coursesError && <p className='error'>{coursesError}</p>}
            <Select
              data-testid='select-input'
              options={allCourses.filter((course) =>
                creditGrantingInstitution?.value
                  ? course.creditGrantingInstitution ===
                    creditGrantingInstitution?.value
                  : true
              )}
              isMulti
              value={courses}
              onChange={selectedCourses => {
                onChange('courses', selectedCourses)
              }}
              placeholder='Select Courses'
              styles={selectInputStyles}
              isDisabled={isDisabled}
              hasError={coursesError}
            />
          </div>
          <Label className='field-label'>Cohorts</Label>
          <div className='text-field'>
            {cohortsError && <p className='error'>{cohortsError}</p>}
            <Select
              data-testid='select-cohort'
              options={courseCohorts}
              isMulti
              value={cohorts}
              onChange={selectedCohorts => {
                onChange('cohorts', selectedCohorts)
              }}
              placeholder='Select Cohorts'
              isDisabled={!(courses || []).length}
              styles={selectInputStyles}
              hasError={cohortsError}
            />
          </div>
          <Label className='field-label'>Remove Access Date</Label>
          <div className='text-field'>
            <DatePickerContainer disabled={isDisabled}>
              <CustomDatePicker
                data-testid='date-field'
                className='date'
                autoComplete='off'
                placeholderText='mm/dd/yyyy'
                selected={date ? new Date(date) : date}
                onChange={date => onChange(
                  'date', date ? date.toISOString().split('T')[0] : null
                )}
                disabled={isDisabled}
              />
            </DatePickerContainer>
          </div>
          <Label className='field-label radio-label'>Access to Graded Content</Label>
          <div className='text-field radio'>
            <Label className='container'>Yes
              <Input
                className='checkbox'
                type='radio'
                name='vipGradedContent'
                checked={formState.vipGradedContent}
                onChange={() => onChange('vipGradedContent', true)}
                disabled={isDisabled}
              />
              <span className='check-mark' />
            </Label>

            <Label className='container'>No
              <Input
                className='checkbox'
                type='radio'
                name='vipGradedContent'
                checked={!formState.vipGradedContent}
                onChange={() => onChange('vipGradedContent', false)}
                disabled={isDisabled}
              />
              <span className='check-mark' />
            </Label>
          </div>
          <Label className='field-label radio-label'>Send Automated Email</Label>
          <div className='text-field radio'>
            <Label className='container'>Yes
              <Input
                data-testid='sendEmail-true'
                className='checkbox'
                type='radio'
                name='sendEmail'
                checked={oldVipStudentData.vipEmailSent || formState.sendEmail}
                onChange={() => onChange('sendEmail', true)}
                disabled={oldVipStudentData.vipEmailSent || isDisabled}
              />
              <span className='check-mark' />
            </Label>

            <Label className='container'>No
              <Input
                data-testid='sendEmail-false'
                className='checkbox'
                type='radio'
                name='sendEmail'
                checked={!oldVipStudentData.vipEmailSent && !formState.sendEmail}
                onChange={() => onChange('sendEmail', false)}
                disabled={oldVipStudentData.vipEmailSent || isDisabled}
              />
              <span className='check-mark' />
            </Label>
          </div>
          <Label className='field-label'>
            Internal Notes (VIP will not see)
          </Label>
          <div className='text-field'>
            <Input
              data-testid='notes-field'
              type='textArea'
              value={notes}
              placeholder='Notes'
              onChange={e => onChange('notes', e.target.value)}
              disabled={isDisabled}
            />
          </div>
          <VipCheckBox>
            Contractor Employee
            <input
              type='checkbox'
              data-testid='contractorEmployee'
              id='contractorEmployee'
              name='contractorEmployee'
              value={contractorEmployee}
              onChange={e => onChange(
                'contractorEmployee', !contractorEmployee
              )}
              checked={contractorEmployee}
              disabled={isDisabled}
            />
            <span />
          </VipCheckBox>
          <ConfirmationModal
            showModalHandler={() => setShowModal(false)}
            show={showModal}
            confirm={{
              text: isEditVipUser ? 'Update' : 'Create',
              handler: () => onConfirmation()
            }}
            cancel={{ text: 'Back', handler: () => setShowModal(false) }}
          >
            {getConfirmationModalText()}
          </ConfirmationModal>
          <AlertModal
            showModalHandler={() => setAlertModal(false)}
            confirm={{ text: 'ok', handler: () => setAlertModal(false) }}
            show={alertModal}>
            <p>{messageOnRequest}</p>
          </AlertModal>
          {loading ? (
            <i className='fa fa-spinner fa-spin mb-5' />
          ) : (
            <Button
              disabled={isDisabled}
              className='submit-button mb-5'
              color='primary'
              onClick={submit}
            >
              Submit
            </Button>
          )}
        </FormGroup>
      </FormWrapper>
    </>
  )
}

export default VipUser
