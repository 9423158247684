import React from 'react'
import {
  Navbar,
  Nav,
  NavLink
} from 'reactstrap'
import { useAuth0 } from '../Auth0Provider/Auth0Provider'
import DropDownMenuComponent from '../DropDownMenuComponent/DropDownMenuComponent'
import hamBars from '../../assets/icons/icon-hambars.svg'
import doorClosed from '../../assets/icons/door-closed.svg'
import toolsIcon from '../../assets/icons/tools.svg'
import OutlierAdminLogo from '../../assets/icons/outlier-admin-logo.svg'
import { Link, withRouter } from 'react-router-dom'
import { Container } from './style'
import JumpToTool from '../JumpToTool/JumpToTool'

function Header () {
  const { user, logout } = useAuth0()
  const logoutWithRedirect = () =>
    logout({
      returnTo: window.location.origin
    })

  return (
    <Container>
      <Navbar light expand='md'>
        <NavLink exact='true' tag={Link} to='/' className='admin-logo'>
          <img src={OutlierAdminLogo} alt='Outlier admin Logo' />
        </NavLink>
        <Nav className='ml-auto d-flex align-items-center' navbar>
          <JumpToTool />
          <DropDownMenuComponent
            iconSrc={hamBars}
            iconWidth='16'
            iconHeight='13.33'>
            <p className='mb-0 dropdown-header'>
              {user && user.email}
            </p>
            <NavLink tag={Link} to='/dashboard' className='dropdown-item'>
              <img src={toolsIcon} alt='tools-icon' className='mr-2' />
            Tools
            </NavLink>
            <button className='dropdown-item' onClick={logoutWithRedirect}>
              <img src={doorClosed} alt='logout-icon' className='mr-2' />
            Log out
            </button>
          </DropDownMenuComponent>
        </Nav>
      </Navbar>
    </Container>
  )
}

export default withRouter(Header)
