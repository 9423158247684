import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Dropdown, DropdownToggle } from 'reactstrap'
import { saveAs } from 'file-saver'
import { Slide, toast } from 'react-toastify'
import {
  AddRecordButton,
  ButtonAsLink,
  DeletedViewToastContainer,
  DropdownGroupSeparator,
  InputError,
  RenameInputContainer,
  RenameViewContainer,
  SubHeaderDropdown,
  SubHeaderDropdownItem,
  SubHeaderWrapper,
  ViewName,
  ViewNameInput
} from './styles'
import { ReactComponent as DropdownArrow } from '../../../assets/icons/dropdown-arrow.svg'
import { ReactComponent as PlusIcon } from '../../../assets/icons/plus.svg'
import { ReactComponent as EditIcon } from '../../../assets/icons/pen-white.svg'
import { ReactComponent as DownloadIcon } from '../../../assets/icons/download.svg'
import { ReactComponent as DeleteIcon } from '../../../assets/icons/trash-icon.svg'
import { ReactComponent as CheckIcon } from '../../../assets/icons/circle-checked-white.svg'
import { ReactComponent as WarningIcon } from '../../../assets/icons/warning.svg'
import { PrimaryButton, SecondaryButton } from '../../global.styled'
import api from '../../../api'
import { getCSVItem } from '../utils'

const DeletedViewToast = ({
  closeToast, deletedView, getViews, tableName, history
}) => {
  const handleUndoDeleteView = async () => {
    closeToast()

    const { id, label, value, ...rest } = deletedView
    await api.addNewView(tableName, rest)
    await getViews(false)

    history.push(`/database-viewer/${tableName}/${deletedView.name}`)
  }
  return (
    <DeletedViewToastContainer data-testid='deleted-view-toast'>
      <CheckIcon />
      <span>View deleted</span>
      <ButtonAsLink
        onClick={handleUndoDeleteView}
        style={{ marginLeft: 'auto' }}
        data-testid='undo-delete-view'
      >
        Undo
      </ButtonAsLink>
    </DeletedViewToastContainer>
  )
}

function SubHeader ({
  currentView,
  openAddRecordModal,
  currentTable,
  views,
  getViews,
  canUserEditDatabase
}) {
  const history = useHistory()
  const { viewName } = useParams()
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [isRenamingView, setIsRenamingView] = useState(false)
  const [newViewName, setNewViewName] = useState('')
  const [inputError, setInputError] = useState('')

  const isDefaultView = viewName === 'Default'

  useEffect(() => {
    setIsRenamingView(false)
  }, [viewName])

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen)
  }

  const showRenameView = () => {
    setNewViewName(viewName)
    setIsDropdownOpen(false)
    setIsRenamingView(true)
  }

  const handleRenameView = async (event) => {
    event.preventDefault()

    if (!newViewName.trim()) return

    const viewNameUsed = views.some((view) => view.label === newViewName.trim())
    if (newViewName.trim().toLowerCase() === 'default' || viewNameUsed) {
      setInputError('Please use a unique view name')
      return
    }

    const { id, label, value, ...rest } = currentView
    await api.updateTableView({
      tableName: currentTable,
      id,
      view: { ...rest, name: newViewName.trim() }
    })

    await getViews(false)
    history.push(`/database-viewer/${currentTable}/${newViewName}`)
    setIsRenamingView(false)
  }

  const jsonToCSV = (json) => {
    const { rows } = json
    const { id, fields } = rows[0]
    const { created_time: _, ...rest } = fields
    const headers = Object.keys({ id, ...rest })
    const csvData = []
    csvData.push(headers.join(',') + '\n')
    rows.forEach(item => {
      const row = headers.map(header => {
        const value = item[header] ?? item.fields[header]
        const csvItem = getCSVItem(value)
        return `${csvItem}`.replace(/(\r\n|\n|\r)/gm, '')
      })
      csvData.push(row.join(',') + '\n')
    })
    return csvData
  }

  const handleGenerateCSV = async () => {
    const { filters, hiddenFields } = currentView

    const data = await api.getTableFilter({
      tableName: currentTable,
      filters,
      hiddenFields,
      limit: 'All'
    })
    const csvData = jsonToCSV(data)

    const fileName = `${currentTable}.csv`
    const blob = new Blob(csvData, { type: 'text/plain;charset=utf-8' })
    saveAs(blob, fileName)
  }

  const handleDeleteView = async () => {
    setIsDropdownOpen(false)

    await api.deleteTableView({ tableName: currentTable, id: currentView.id })
    await getViews(false)

    toast(
      <DeletedViewToast
        deletedView={currentView}
        getViews={getViews}
        tableName={currentTable}
        history={history}
      />,
      {
        className: 'toastContainer',
        autoClose: 10000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        closeButton: false,
        transition: Slide,
        onOpen: () => history.push(`/database-viewer/${currentTable}/Default`)
      }
    )
  }

  return (
    <SubHeaderWrapper>
      {isRenamingView ? (
        <RenameViewContainer onSubmit={handleRenameView}>
          <RenameInputContainer>
            {inputError && (
              <InputError data-testid='input-error'>
                <WarningIcon />
                {inputError}
              </InputError>
            )}
            <ViewNameInput
              data-testid='rename-view-input'
              type='text'
              error={inputError}
              autoFocus
              value={newViewName}
              onChange={(event) => {
                if (inputError) {
                  setInputError('')
                }
                setNewViewName(event.target.value)
              }}
              style={{ height: '56px', width: '350px' }}
            />
          </RenameInputContainer>

          <PrimaryButton type='submit' disabled={inputError}>
            Save
          </PrimaryButton>
          <SecondaryButton onClick={() => setIsRenamingView(false)}>
            Cancel
          </SecondaryButton>
        </RenameViewContainer>
      ) : (
        <Dropdown isOpen={isDropdownOpen} toggle={toggleDropdown}>
          <DropdownToggle data-toggle='dropdown' tag='div'>
            <ViewName isDropdownOpen={isDropdownOpen}>
              {viewName}
              <DropdownArrow />
            </ViewName>
          </DropdownToggle>
          <SubHeaderDropdown>
            <SubHeaderDropdownItem
              data-testid='rename-view-button'
              onClick={showRenameView}
              disabled={isDefaultView}
            >
              <EditIcon />
              <span>Rename view</span>
            </SubHeaderDropdownItem>
            <SubHeaderDropdownItem
              data-testid='generate-csv-button'
              onClick={handleGenerateCSV}
            >
              <DownloadIcon />
              <span>Generate CSV</span>
            </SubHeaderDropdownItem>
            <DropdownGroupSeparator />
            <SubHeaderDropdownItem
              disabled={isDefaultView}
              onClick={handleDeleteView}
              data-testid='delete-view-button'
            >
              <DeleteIcon />
              <span>Delete view</span>
            </SubHeaderDropdownItem>
          </SubHeaderDropdown>
        </Dropdown>
      )}
      {canUserEditDatabase &&
        <AddRecordButton onClick={openAddRecordModal}>
          <PlusIcon />
        Record
        </AddRecordButton>
      }
    </SubHeaderWrapper>
  )
}

SubHeader.displayName = 'SubHeader'
export default SubHeader
