import React, { useMemo, useRef, useState } from 'react'
import ModalComponent from '../../Components/ModalComponent/ModalComponent'
import {
  ButtonContainer,
  FormContainer, GoBackButton, InfoContainer,
  InfoText, InfoTitle,
  Input,
  InputContainer,
  Label,
  Row,
  WarningMessage
} from './styles/index.styled'
import warningIcon from '../../assets/icons/warning.svg'
import CustomSelect from '../../Components/CustomSelect/UpdatedSelect'
import Button from '../../Components/Button/Button'
import PropTypes from 'prop-types'
import api from '../../api'
import Toast from '../../Components/ToastComponent/Toast'
import { SuccessIcon } from '../../Components/AdminStudentDrop/styled'
import chevronLeft from '../../assets/icons/back-arrow-caret.svg'
import { validateOutlierEmail } from '../../utilities/permissionsManagerUtil'

const AddUserModal = ({ showAddUserModal, setShowAddUserModal, roles }) => {
  const toast = useRef()
  const [isWarningVisible, setIsWarningVisible] = useState(false)
  const [modalStep, setModalStep] = useState(0)
  const [formData, setFormData] = useState({
    firstName: '', lastName: '', email: '', role: ''
  })

  const rolesOptions = useMemo(() => roles.map(role => ({
    value: role.role,
    label: role.role.charAt(0).toUpperCase() + role.role.slice(1)
  })), [roles])

  const handleInputChange = (e) => {
    const { id, value } = e.target
    setFormData({
      ...formData, [id]: value
    })
  }

  const handleSelectChange = (selectedOption) => {
    setFormData({
      ...formData, role: selectedOption.value
    })
  }

  const disableContinueButton = !formData.firstName || !formData.lastName ||
    !formData.email || !formData.role

  const goToNextStep = () => {
    if (!validateOutlierEmail(formData.email)) {
      setIsWarningVisible(true)
      return
    }
    setIsWarningVisible(false)
    setModalStep(1)
  }
  const resetState = () => {
    setModalStep(0)
    setFormData({
      firstName: '', lastName: '', email: '', role: ''
    })
  }

  const checkEmailValidation = (email) => {
    if (email === '') return setIsWarningVisible(false)
    if (!validateOutlierEmail(email)) {
      setIsWarningVisible(true)
    } else {
      setIsWarningVisible(false)
    }
  }

  const handleAddUser = async () => {
    const { success } = await api.addAdminV2(formData) || {}
    resetState()
    setShowAddUserModal(false)
    const { display } = toast.current || {}
    display && display(
      success
        ? <><SuccessIcon /> User successfully added!</>
        : <>Error adding user</>,
      success
        ? "Visit the user's permissions page if you wish to customize their access."
        : 'Something went wrong. Please try again later.'
    )
  }

  return (
    <>
      <Toast toast={toast} />
      <ModalComponent
        show={showAddUserModal}
        handleClose={() => setShowAddUserModal(false)}
        position='center'
        title={
          modalStep === 0
            ? 'Add new user'
            : <GoBackButton
              onClick={() => setModalStep(0)}
            >
              <img src={chevronLeft} alt='chevron-left' />
              Go back
            </GoBackButton>
        }
        width={'450px'}
        isResponsive
      >
        {modalStep === 0 && <FormContainer>
          <Row>
            <InputContainer>
              <Label htmlFor='firstName'>First name</Label>
              <Input id='firstName'
                type='text'
                placeholder='First name'
                value={formData.firstName}
                onChange={handleInputChange}
              />
            </InputContainer>
            <InputContainer>
              <Label htmlFor='lastName'>Last name</Label>
              <Input
                id='lastName'
                type='text'
                placeholder='Last name'
                value={formData.lastName}
                onChange={handleInputChange}
              />
            </InputContainer>
          </Row>
          <Label htmlFor='email'>Email</Label>
          {isWarningVisible && <WarningMessage>
            <img src={warningIcon} alt='warning' style={{ marginRight: '8px' }} />
            Please enter an Outlier.org or Savvas.com email
          </WarningMessage>}
          <Input
            id='email'
            type='email'
            placeholder='Enter an Outlier.org or Savvas.com email'
            value={formData.email}
            onChange={handleInputChange}
            isWarningVisible={isWarningVisible}
            onBlur={() => checkEmailValidation(formData.email)}
          />
          <Label htmlFor='role'>Role</Label>
          <CustomSelect
            id='role'
            options={rolesOptions}
            placeholder='Select role'
            width='100%'
            height='48px'
            backgroundColor='#161618'
            value={rolesOptions.find(option => option.value === formData.role)}
            onChange={handleSelectChange}
          />
          <InfoText>
            You can customize tool access after adding the user,
          </InfoText>
          <ButtonContainer>
            <Button
              className={{
                'btn-custom': true,
                'btn-primary': true,
                disabled: disableContinueButton
              }}
              style={{
                padding: '16px 24px',
                height: '48px',
                marginTop: '24px'
              }}
              onClick={goToNextStep}
              disabled={disableContinueButton}
            >
              Continue
            </Button>
          </ButtonContainer>
        </FormContainer>}
        {modalStep === 1 &&
          <InfoContainer>
            This user won’t receive an invite email. They must create an Auth0
            account.
            <div>
              <InfoTitle>{formData.firstName} {formData.lastName}</InfoTitle>
              <div>{formData.email}</div>
            </div>
            <div>
              <InfoTitle>{formData.role.charAt(0).toUpperCase() + formData.role.slice(1)}</InfoTitle>
              <div>{roles.find(role => role.role === formData.role)?.description}</div>
            </div>
            <ButtonContainer>
              <Button
                className={{
                  'btn-custom': true,
                  'btn-primary': true,
                  disabled: disableContinueButton
                }}
                style={{ padding: '16px 24px', height: '48px' }}
                onClick={handleAddUser}
                disabled={disableContinueButton}
              >
                Add user
              </Button>
            </ButtonContainer>
          </InfoContainer>}
      </ModalComponent>
    </>)
}
AddUserModal.propTypes = {
  showAddUserModal: PropTypes.bool.isRequired,
  setShowAddUserModal: PropTypes.func.isRequired
}
export default AddUserModal
