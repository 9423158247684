import React, { useEffect, useState, useRef, useMemo } from 'react'
import CustomVideo from '../VideoPlayer/VideoPlayer'
import { useHistory, useParams } from 'react-router-dom'
import { BroadcastChannel } from 'broadcast-channel'
import { useAuth0 } from '../Auth0Provider/Auth0Provider'
import moment from 'moment'
import {
  GlobalStyle,
  IndividualSubmissionContainer,
  ScoreContainer,
  Score,
  SuccessModalWrapper,
  SubmissionsContainer,
  GradingSidebar,
  WarningMessage,
  GoToPreviousPageButtonWrapper,
  ButtonsContainer,
  ActionButton,
  Assignment
} from './style'
import GoToPreviousPageButton from
'../GoToPreviousPageButton/GoToPreviousPageButton'
import AssignmentInformationSection from './AssignmentInformationSection'
import api from '../../api'
import { getApiHost } from '../../config'
import { wait } from '../../utilities'
import {
  getSubmissionTime,
  getFormattedTime
} from '../../utilities/writingGradeCenterUtils'
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'
import AssignmentPreview from './AssignmentPreview'
import ModalComponent from '../ModalComponent/ModalComponent'
import CheckMark from '../../assets/icons/icon-checkmark.svg'
import CloseButton from '../../assets/icons/cross.png'
import {
  useWritingGradeCenterContext,
  useWritingGradeCenterActions
} from '../../contexts/WritingGradeCenterContext'
import { checkPermission } from '../../utilities/permissionsManagerUtil'
import {
  standardizeToEasternTime
} from '../../utilities/dateTimeUtil'
import { FeedbackFileUpload } from './FeedbackFileUpload'
import RichTextEditor from '../RichTextEditor/RichTextEditor'
import {
  convertTextToPdf
} from './utils'
import { WRITING_ASSIGNMENT_GRADED_GGU } from '../../Constants/eventTypes'
import { MULTIPLE } from '../../Constants/eventFrequency'
import { WGC_CHANNEL } from '../../Constants/broadcastChannels'
import SubmissionsWrapper from './SubmissionsWrapper'
import WarningIcon from '../../assets/icons/icon-red-warning.svg'
import { getStudentProgress } from '../../utilities/gradeReportUtils'
import { hasCohortEndFourYearsAgoOrMore } from '../../utilities/cohortUtils'

const Warning = ({ text, maxWidth }) => {
  return (
    <WarningMessage maxWidth={maxWidth}>
      <img src={WarningIcon} alt='Warning' />
      <p>{text}</p>
    </WarningMessage>
  )
}

const StudentSubmission = () => {
  const { user: { nickname, sub } } = useAuth0()
  const {
    studentsByCohort,
    currentAssignment
  } = useWritingGradeCenterContext()
  const {
    multiPartUpload,
    fileType1, fileType2, fileType3, fileType4, fileType5,
    fileName1, fileName2, fileName3, fileName4, fileName5
  } = currentAssignment || {}
  const smallScreens = window.matchMedia('(max-width: 991px)').matches

  const {
    fetchStudentsByCohort,
    fetchCurrentAssignment } = useWritingGradeCenterActions()
  const history = useHistory()
  const params = useParams()
  const [grade, setGrade] = useState('')
  const [errors, setErrors] = useState({ grades: '', feedback: '' })
  const [feedback, setFeedback] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [contents, setContent] = useState([])
  const [studentProgress, setStudentProgress] = useState(null)
  const [assignment, setAssignment] = useState()
  const [cohortId, setCohortId] = useState(null)
  const [studentId, setStudentId] = useState(null)
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [canGradeAssignments, setCanGradeAssignments] = useState(false)
  const [kamiUploadStatus, setKamiUploadStatus] = useState(
    { isLoading: false, isError: false }
  )
  const [showCompletionModal, setShowCompletionModal] = useState(false)
  const { courseId, studentEmail: encodedStudentEmail,
    cohortName: encodedCohortName, assignmentUUID } = params
  const cohortName = decodeURIComponent(encodedCohortName)
  const studentEmail = decodeURIComponent(encodedStudentEmail)

  const assignmentRef = useRef(false)
  const gradingStartTime = useRef(new Date())

  const isGGUCohort = useMemo(
    () => cohortName?.toLowerCase().includes('ggu'),
    [cohortName]
  )

  useEffect(() => {
    const getAssignmentAndProgressData = async () => {
      setIsLoading(true)
      assignmentRef.current = true
      try {
        const promises = []
        promises.push(getStudentProgress({
          cohortId,
          courseId,
          isCurrentCohort: true,
          studentEmail }))
        promises.push(fetchCurrentAssignment({
          courseId, assignmentUUID, cohortName }))

        const [studentProgress] =
          await Promise.all(promises)
        setStudentProgress(studentProgress)
      } catch (error) {
        console.error(error.message)
      } finally {
        assignmentRef.current = false
        setIsLoading(false)
      }
    }

    getAssignmentAndProgressData()
    // eslint-disable-next-line
  }, [studentEmail, cohortName, assignmentUUID])

  useEffect(() => {
    if (!studentProgress) return
    const { 'assignment-progress': assignmentProgress } = studentProgress || {}
    const { cohortId, grade, feedback } = (assignmentProgress &&
      assignmentProgress[assignmentUUID]) || {}
    setCohortId(cohortId)
    setProgressGrade(grade)

    feedback && setFeedback(feedback)
    setGrade(grade && grade >= 0
      ? Math.round(((grade / 100) * maxScore) * 100) / 100
      : grade)

    // eslint-disable-next-line
  }, [studentProgress, assignmentUUID])

  const verifyPermissions = async () => {
    const GRADE_WRITTEN_ASSIGNMENTS = 'writingGradeCenter.view'
    const gradeWrittenAssignmentPermission = await checkPermission(
      GRADE_WRITTEN_ASSIGNMENTS
    )

    setCanGradeAssignments(gradeWrittenAssignmentPermission)
  }

  useEffect(() => {
    verifyPermissions()
  }, [])

  const changeStudentId = () => {
    const students = studentsByCohort && studentsByCohort[cohortId]
    if (!students) return

    const student = students.find(student =>
      student.email === studentEmail)
    student && setStudentId(student.id)
  }

  useEffect(() => {
    if (!cohortId) return

    const getAssignmentDetails = async () => {
      setIsLoading(true)

      const promises = []
      const files = [
        { name: fileName1, type: fileType1 },
        { name: fileName2, type: fileType2 },
        { name: fileName3, type: fileType3 },
        { name: fileName4, type: fileType4 },
        { name: fileName5, type: fileType5 }
      ].filter(file => file.type?.length) || []

      if (multiPartUpload && files.length) {
        files.forEach((file, index) => {
          const { type } = file
          if (type[0] !== '.mp4') {
            return promises.push(api.getWritingAssignment({
              courseId,
              cohortID: cohortId,
              assignmentUUID,
              studentEmail: encodedStudentEmail,
              fileName: `file${index}`,
              multiPartAssignment: true
            }))
          }
          const apiHost = getApiHost()
          promises.push({
            streamUrl: `${apiHost}/student/writing-assignment/${courseId}/${cohortId}/${assignmentUUID}/file${index}?studentEmail=${encodedStudentEmail}&multiPartAssignment=true`
          })
        })
      } else {
        promises.push(api.getWritingAssignment({
          courseId,
          cohortID: cohortId,
          assignmentUUID,
          studentEmail: encodedStudentEmail
        }))
      }

      if (!studentsByCohort ||
        !Object.keys(studentsByCohort).includes(cohortId)) {
        promises.push(fetchStudentsByCohort(cohortId))
      }

      const responses = await Promise.all(promises)
      if (!assignmentRef.current) setIsLoading(false)
      setAssignment(responses
        .filter(response => response)
        .map((response, index) => {
          if (!multiPartUpload || !files.length) return response
          return {
            ...response,
            fileName: files[index]?.name,
            type: files[index]?.type
          }
        })
      )
      changeStudentId()
    }

    getAssignmentDetails()
    // eslint-disable-next-line
  }, [cohortId, assignmentUUID, currentAssignment])

  useEffect(() => {
    changeStudentId()
    // eslint-disable-next-line
  }, [studentsByCohort])

  const readTextContent = ({
    data,
    fileName,
    type,
    isFile
  }) => {
    const blob = new Blob([data], { type: 'text/plain' })
    const reader = new FileReader()
    reader.addEventListener('load', () => {
      const { result } = reader
      if (!result) {
        return setContent(preVal => [
          ...preVal,
          { text: '', fileName, type }
        ])
      }
      try {
        let blob
        if (isFile) {
          blob = new Blob([result], { type: 'text/plain' })
          setContent(preVal => [
            ...preVal,
            {
              text: result,
              url: URL.createObjectURL(blob),
              fileName,
              type
            }])
        } else {
          const text = JSON.parse(result)
          blob = new Blob([text.assignmentHTML], { type: 'text/plain', fileName })
          setContent(preVal => [
            ...preVal,
            {
              text: text.assignmentHTML,
              url: URL.createObjectURL(blob),
              fileName,
              type
            }
          ])
        }
      } catch (error) {
        console.log('Error when parsing text json: ', error.message)
        setContent(preVal => [...preVal, { text: '', fileName, type }])
      }
    })
    reader.readAsText(blob)
  }

  const readFileContent = ({
    data,
    fileName,
    fileType,
    type
  }) => {
    const { 'assignment-file-metadata': assignmentFileMetadata } = studentProgress || {}
    const fileMetadata = assignmentFileMetadata && assignmentFileMetadata[assignmentUUID]
    const { originalFileName } = fileMetadata || {}
    const blob = new Blob([data], { type: fileType })
    setContent(preVal => [
      ...preVal,
      {
        url: URL.createObjectURL(blob),
        fileType,
        type,
        name: originalFileName || currentAssignment.title.split(' ').join(''),
        fileName
      }
    ])
  }

  useEffect(() => {
    if (!assignment) return
    assignment.forEach(submission => {
      const {
        error,
        fileName,
        type,
        streamUrl,
        data,
        headers: { 'content-type': fileType } = {}
      } = submission
      if (error) return setContent(prev => [...prev, { text: error, type }])
      if (streamUrl) return setContent(prev => [...prev, { streamUrl, fileName, type }])

      if (!fileType) return null
      if (fileType === 'application/json' || fileType === 'text/plain') {
        readTextContent({ data, type, fileName, isFile: fileType === 'text/plain' })
      } else {
        readFileContent({ data, type, fileName, fileType })
      }
    })
    // eslint-disable-next-line
  }, [assignment])

  const handleGoBackClick = () => {
    history.push({
      pathname: '/writing-grade-center'
    })
  }

  const validateGrade = (newGrade, newFeedback) => {
    const hasNoGrade = (!newGrade || newGrade < 0) && newGrade !== 0
    if (hasNoGrade) {
      setErrors(prevState => ({ ...prevState, grades: 'Grade is mandatory' }))
      setShowCompletionModal(false)
      return
    }

    if (isNaN(newGrade)) {
      setErrors(prevState => ({ ...prevState, grades: 'This entry can only contain numbers' }))
      setShowCompletionModal(false)
      return
    }

    if (newGrade > maxScore) {
      setErrors(prevState => ({ ...prevState, grades: 'Grade cannot exceed 100%' }))
      setShowCompletionModal(false)
      return
    }

    setShowCompletionModal(newGrade !== '' && isValidRichtext(newFeedback || feedback))
  }

  const isValidRichtext = text => text && text !== '<p><br></p>'

  const validateFeedback = (newFeedback) => {
    if (isValidRichtext(newFeedback)) return validateGrade(grade, newFeedback)
    setErrors(prevState => ({ ...prevState, feedback: 'Feedback is mandatory' }))
    setShowCompletionModal(false)
  }

  const submitGrade = async () => {
    setIsLoading(true)
    const calculatedGrade = ((grade / maxScore) * 100).toFixed(2)
    const { 'assignment-progress': assignmentProgress } = studentProgress || {}
    const submittedProgress = assignmentProgress ? assignmentProgress[assignmentUUID] : {}
    const response = await api.setStudentProgress(
      { courseId, key: 'assignment-progress' },
      studentEmail, { [assignmentUUID]: {
        grade: parseFloat(calculatedGrade), feedback, ...submittedProgress } })
    setIsLoading(false)
    if (!response) return
    setProgressGrade(parseFloat(calculatedGrade))
    setShowSuccessModal(true)

    trackGGUAssignmentGradedEvent()
  }

  useEffect(() => {
    if (!showSuccessModal) return
    const broadcastMessage = new BroadcastChannel(WGC_CHANNEL)
    broadcastMessage.postMessage({ refetchGrades: true })
    return () => broadcastMessage.close()
    // eslint-disable-next-line
  }, [showSuccessModal])

  const getKamiDocumentData = () => {
    const assignmentData = Array.isArray(assignment) ? assignment?.[0] : assignment
    const {
      data, headers: { 'content-type': fileType } = {}
    } = assignmentData || {}
    const {
      'assignment-file-metadata': assignmentFileMetadata = {}
    } = studentProgress || {}
    const fileMetadata = assignmentFileMetadata[assignmentUUID]
    let { originalFileName } = fileMetadata || {}
    let blob

    if (fileType === 'application/json' || fileType === 'text/plain') {
      const doc = convertTextToPdf(data, fileType)
      blob = doc.output('blob')
      originalFileName = `${assignmentUUID}.pdf`
    } else {
      blob = new Blob([data], { type: fileType })
    }

    const formData = new FormData()
    formData.append('kami-assignment-file', blob, originalFileName)

    return formData
  }

  const getKamiDocumentStatus = async (urlParams, documentIdentifier) => {
    let kamiResponse = {}
    let isUploadingDocument = true

    while (isUploadingDocument) {
      await wait(2000)

      const { data, errorMessage } = await api.getKamiDocument(
        { ...urlParams, documentIdentifier }
      )

      if (['done', 'error'].includes(data?.file_status)) {
        kamiResponse = data
        isUploadingDocument = false
      }
      if (errorMessage) {
        kamiResponse = {
          file_status: 'error'
        }
        isUploadingDocument = false
      }
    }

    return kamiResponse
  }

  const getKamiViewSessionUrl = async (documentIdentifier) => {
    const expiryDate = new Date(
      new Date().setHours(new Date().getHours() + 24)
    ).toISOString() // after 24 hours
    const body = {
      document_identifier: documentIdentifier,
      user: {
        name: nickname,
        user_id: sub.slice(0, -3)
      },
      expires_at: expiryDate,
      editable: true
    }

    const { data, errorMessage } = await api.createKamiViewSession(encodedStudentEmail, body)

    return {
      viewSessionUrl: data?.viewer_url,
      viewSessionError: errorMessage
    }
  }

  const handleAnnotateSubmission = async () => {
    if (kamiUploadStatus.isLoading) return

    const formData = getKamiDocumentData()
    if (!formData) return

    setKamiUploadStatus({ isError: false, isLoading: true })
    const urlParams = {
      studentEmail: encodedStudentEmail, courseId, cohortId, assignmentUUID
    }
    const { data: putKamiResponse, errorMessage } = await api.putKamiDocument(
      formData, urlParams
    )

    if (errorMessage) {
      return setKamiUploadStatus({ isError: true, isLoading: false })
    }

    const {
      document_identifier: documentIdentifier, file_status: oldFileStatus
    } = putKamiResponse || {}

    let kamiResponse = putKamiResponse
    const isUploadingDocument = oldFileStatus !== 'done'

    if (isUploadingDocument) {
      const getKamiResponse = await getKamiDocumentStatus(urlParams, documentIdentifier)
      kamiResponse = getKamiResponse
    }

    const { file_status: newFileStatus } = kamiResponse

    if (newFileStatus === 'error') {
      return setKamiUploadStatus({ isError: true, isLoading: false })
    }

    const { viewSessionUrl, viewSessionError } = await getKamiViewSessionUrl(
      documentIdentifier
    )
    if (viewSessionError) return setKamiUploadStatus({ isError: true, isLoading: false })

    window.open(viewSessionUrl, '_blank')
    setKamiUploadStatus({ isError: false, isLoading: false })
  }

  const {
    maxScore,
    title,
    assignmentDetails,
    rubric,
    cohortMilestone: { lockTime, cohortEndTime } = {}
  } = currentAssignment || {}

  const isCohortEndFourYearsAgo = hasCohortEndFourYearsAgoOrMore(cohortEndTime)

  const { 'assignment-progress': assignmentProgress } = studentProgress || {}
  const { submissionTime, grade: gradeFromProgress } =
    (assignmentProgress && assignmentProgress[assignmentUUID]) || {}

  const formattedSubmissionTime = getSubmissionTime(submissionTime)

  const recordSubmissionData = () => {
    if (isGGUCohort) return

    const analyticsData = {
      event: 'assignmentSubmission',
      properties: {
        studentID: studentId,
        submission_time: standardizeToEasternTime(new Date(formattedSubmissionTime)),
        grade_submission_time: standardizeToEasternTime(new Date()),
        cohort: cohortName,
        assignment: currentAssignment?.label,
        deadline: lockTime && moment(
          new Date(lockTime).toLocaleDateString('en-US', { timeZone: 'America/New_York' }),
          'M-D-YYYY'
        ).format('MM-DD-YY'),
        grade_start_time: standardizeToEasternTime(gradingStartTime.current)
      }
    }

    api.captureWGCAnalytics(analyticsData)
  }

  const trackGGUAssignmentGradedEvent = async () => {
    if (!isGGUCohort) return

    const [course, { data: prospectsData }] = await Promise.all([
      api.getCourse(courseId),
      api.getProspectsData(studentEmail)
    ])

    const gguTerm = prospectsData?.[0]?.term

    const eventData = {
      event: WRITING_ASSIGNMENT_GRADED_GGU,
      properties: {
        studentID: studentId,
        course: course?.name,
        cohort: cohortName,
        term: gguTerm,
        assignment_name: currentAssignment?.label,
        timestamp: Date.now()
      },
      frequency: MULTIPLE
    }

    api.captureWGCAnalytics(eventData)
  }

  const [progressGrade, setProgressGrade] = useState(gradeFromProgress)
  const assignmentDeadline = new Date(lockTime) / 1000
  const formattedDeadline = getFormattedTime(assignmentDeadline)
  const isEditorDisabled = !!progressGrade || progressGrade === 0 ||
  !canGradeAssignments

  return (
    <>
      <GlobalStyle />
      <ModalComponent
        position='top'
        show={showSuccessModal}
        handleClose={() => setShowSuccessModal(false)}>
        <SuccessModalWrapper>
          <img src={CheckMark} alt='Check Mark' />
            Grade for {studentId} has successfully been submitted
          <img src={CloseButton} alt='Close' onClick={() => setShowSuccessModal(false)} />
        </SuccessModalWrapper>
      </ModalComponent>
      <GoToPreviousPageButtonWrapper>
        <GoToPreviousPageButton
          text={'Dashboard'}
          handleClick={handleGoBackClick}
        />
      </GoToPreviousPageButtonWrapper>
      {isLoading ? <LoadingSpinner />
        : <>
          <AssignmentInformationSection
            title={`${title} - ${studentId}`}
            formattedDeadline={formattedDeadline}
            cohortName={cohortName}
            assignmentDetails={assignmentDetails}
            params={params}
            history={history}
            assignmentProgress={assignmentProgress}
            rubric={rubric}
          />
          {contents.length && <IndividualSubmissionContainer
            showCompletionModal={showCompletionModal}
          >
            <SubmissionsContainer
              showCompletionModal={showCompletionModal}
              overflowY={contents.length <= 1 ? 'hidden' : 'auto'}
            >
              {contents.map((content, index) => {
                return (
                  <SubmissionsWrapper
                    key={index}
                    content={content}
                    kamiUploadStatus={kamiUploadStatus}
                    handleAnnotateSubmission={handleAnnotateSubmission}
                    isCohortEndFourYearsAgo={isCohortEndFourYearsAgo}
                  >
                    <Assignment>
                      {content.streamUrl
                        ? (
                          <CustomVideo
                            videoUrl={content.streamUrl}
                            controls
                            width='100%'
                            height='100%'
                            isCohortEndFourYearsAgo={isCohortEndFourYearsAgo}
                          />
                        )
                        : <AssignmentPreview content={content} title={title} />
                      }
                    </Assignment>
                  </SubmissionsWrapper>
                )
              })}
            </SubmissionsContainer>
            <GradingSidebar feedbackMarginBottom='12px'>
              <p className='label'>Points earned</p>
              {errors?.grades && <Warning maxWidth='180px' text={errors.grades} />}
              <ScoreContainer>
                <Score
                  data-testid='grade-input'
                  disabled={progressGrade || progressGrade === 0 || !canGradeAssignments}
                  value={grade ?? ''}
                  onChange={event => {
                    const newValue = event.target.value
                    setGrade(newValue)
                    setErrors(prevState => ({ ...prevState, grades: '' }))
                    validateGrade(newValue)
                  }}
                />
                <span className='equal'>=</span>
                <span className='grade'>
                  {grade ? Math.round(((grade / maxScore) * 100) * 100) / 100 : 'N/A'}
                </span>
                <span>%</span>
              </ScoreContainer>
              <p className='out-of'>Out of {maxScore}</p>
              <p className='label'>Feedback</p>
              <p className='feedback-label'>General feedback</p>
              {errors?.feedback && <Warning text={errors.feedback} />}
              <RichTextEditor
                isDisabled={isEditorDisabled}
                setValue={value => {
                  setFeedback(value)
                  setErrors(prevState => ({ ...prevState, feedback: '' }))
                  validateFeedback(value)
                }}
                value={feedback}
              />

              {!progressGrade && progressGrade !== 0 && canGradeAssignments && (
                <FeedbackFileUpload
                  label='Additional feedback - Optional'
                  marginTop='12px'
                  fileUploadMarginTop='8px'
                  studentEmail={encodedStudentEmail}
                  courseId={courseId}
                  cohortId={cohortId}
                  assignmentUUID={assignmentUUID}
                />
              )}

              <p className='annotation'>
                Share an annotated rubric or submission, or
                &nbsp;
                <button
                  disabled={kamiUploadStatus.isLoading}
                  error={kamiUploadStatus.isError}
                  onClick={handleAnnotateSubmission}
                  data-testid='annotate-submission'
                >
                  annotate the submission in the browser
                </button>.
              </p>
              {kamiUploadStatus.isError && (
                <Warning text='Error uploading document.' />
              )}
            </GradingSidebar>
          </IndividualSubmissionContainer>}
          {smallScreens ? null : <div style={{ height: '300px', opacity: 0, zIndex: 0 }} />}
          {showCompletionModal && (
            <ButtonsContainer>
              <p>Ready to submit the grade?</p>
              <ActionButton
                className='btn-custom btn-primary button'
                onClick={async () => {
                  await submitGrade()
                  recordSubmissionData()
                  setShowCompletionModal(false)
                }}
              >
                SUBMIT GRADE
              </ActionButton>
              <ActionButton
                className='btn-custom btn-secondary button ml-2'
                onClick={() => {
                  handleGoBackClick()
                  setShowCompletionModal(false)
                }}>
                  CANCEL
              </ActionButton>
            </ButtonsContainer>
          )}
        </> }
    </>
  )
}

StudentSubmission.displayName = 'StudentSubmission'

export default StudentSubmission
