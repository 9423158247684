import { useState, useEffect } from 'react'
import api from '../../api'

const videoCache = new Map()

const useFetchVideo = (videoUrl) => {
  const [url, setUrl] = useState(null)
  const [isUrlLoading, setIsUrlLoading] = useState(false)
  const [isFileNotFound, setIsFileNotFound] = useState(false)

  useEffect(() => {
    if (!videoUrl) {
      setUrl(null)
      setIsUrlLoading(false)
      setIsFileNotFound(false)
      return
    }

    // Check if the videoUrl is already in the cache
    if (videoCache.has(videoUrl)) {
      const cachedData = videoCache.get(videoUrl)
      setUrl(cachedData.url)
      setIsUrlLoading(false)
      setIsFileNotFound(cachedData.isFileNotFound)
      return
    }

    const fetchVideo = async () => {
      setIsUrlLoading(true)
      setIsFileNotFound(false)
      try {
        const token = await api.getToken()
        const options = {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
        const response = await fetch(videoUrl, options)
        if (!response.ok) {
          if (response.status === 404) {
            setIsFileNotFound(true)
            videoCache.set(videoUrl, { isFileNotFound: true, url: null })
          }
          throw new Error(`HTTP error! status: ${response.status}`)
        }
        const blob = await response.blob()
        const videoObjectUrl = URL.createObjectURL(blob)
        setUrl(videoObjectUrl)
        videoCache.set(videoUrl, { isFileNotFound: false, url: videoObjectUrl })
      } catch (e) {
        console.error(e)
      } finally {
        setIsUrlLoading(false)
      }
    }

    fetchVideo()
  }, [videoUrl])

  return { url, isUrlLoading, isFileNotFound }
}

export default useFetchVideo
